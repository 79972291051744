import React from "react";
import ReactTooltip from "react-tooltip";


const Tooltip = (props) => {
  const { children, text, css } = props

  return (
    <div data-tip={text} css={css}>
      {children}
      <ReactTooltip />
    </div>
  )
}


export default Tooltip;
