import firebase from "firebase/app";


export const REPORTURL_REASON = {
  ERROR: "error",
  INNAPROPRIATE: "innapropriate content",
  REQUEST_MEDIAS: "request medias",
}


const reportUrl = async (linkId, provider, text, options = {}) => {
  const reportUrlReason = options.reportUrlReason || "feedback";
  const email = options.email || "";

  if (!linkId || !provider || !reportUrlReason) {
    console.error("[ReportUrl]", linkId, provider, reportUrlReason);
    return;
  }

  const db = firebase.firestore();
  await db.collection("contacts").add({
    text,
    email,
    linkId,
    url: provider.url,
    reason: reportUrlReason,
    providerId: provider.id,
    createdAt: new Date(),
  });
}


export const contactUs = async (linkId, text, email) => {
  const db = firebase.firestore();
  await db.collection("contacts").add({
    text,
    email,
    linkId,
    reason: "feedback",
    createdAt: new Date(),
  });
}


export default reportUrl;