import React, { Component } from 'react';
import { jsx, css, keyframes, Global } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SocialIcon } from 'react-social-icons';
import {renderRandomBackground} from "../../image";
import { Link } from "react-router-dom";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import LateralMenu from "../../components/lateralMenu.component";
import Theme from "../../theme";
import {style} from "../../styles/common";
import {faCopyright} from "@fortawesome/free-regular-svg-icons/faCopyright";
import Button from "../../components/button.component";


const dark = css`
   width: 100%;
   font-size: 14px;
   span {
    font-weight: 600;
   }
`
const modeOption = props => css`
  color: ${props.mode === "dark" ? style(props).color.violet : style(props).color.switchableTextColor};
`

export default class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visibleMenu: false
        }
    }

    render() {
        const { currentLink, links, count, totalClicks, totalView, children, menu, avatar, noMenu } = this.props;
        const { mode, switchTheme } = this.context;

        const header = css`
          background: ${style(mode).color.headerBackground};
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          height: 200px;
          max-height: 200px;
          color: #000000;
          top: 0;
          font-weight: 500!important;
          width: 100%;
          margin-left: -30px;
          left: 0;
          font-size: 24px;
          box-shadow: ${style(mode).shadowSoft};
          padding-left: 25px;
          padding-right: 25px;
          transition: all .6s ease-out;

          @media (max-width: ${style(mode).mobile}) {
            display: none;
            position: fixed;
            height: 80px;
            max-height: 80px;
            margin-top: 0;
            width: 100%;
            top:0;
            z-index:101;
          }

        `;

        const headerContent = css`
            display: flex;
            z-index: 100;
            justify-content: space-between;
            align-items: center;
            height: 200px;
            max-height: 200px;
            color: #000000;
            top: 0;
            font-weight: 500!important;
            width: 100%;
            left: 0;
            font-size: 24px;
            border-bottom-left-radius: ${style(mode).mainRadius};
            border-bottom-right-radius: ${style(mode).mainRadius};
          padding-left: 70px;

          padding-right: 50px;
            transition: all .6s ease-out;
        `;

        const socials = css`
            color: ${style(mode).color.switchableTextColor};
            font-size: 14px;
            font-weight: 300;
            margin-top: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: flex-start;
            overflow: hidden;
            text-decoration: none;         
            flex-direction: column;
            height: 100%;
            flex-wrap: wrap;
            transition: all .6s ease-in-out;
        `;

        const subSocial = css`
            margin-bottom: 5px;
            font-weight: 300!important;
            display: flex;
          div {          
            min-width: 30px;
            padding-right: 10px;
            color: ${style(mode).color.violet};
            font-weight: 500!important;
            text-align: right;
            }
          
          button {
            margin-top: 10px;
            margin-bottom: 0;
          }
        `;

        const subSocialTitle = css`
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 16px;
            color: ${style(mode).color.violet};
        `;

        const headerLeft = css`
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;      
            
            a {
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-right: 10px;
            }
            
             @media (max-width: ${style(mode).mobile}) {
               display: none;
             }
            transition: all .6s ease-in-out;
        `;

        const headerRight = css`
            display: inline-flex;
            width: 50%;
            color: ${style(mode).color.switchableTextColor};
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            flex-shrink: 0;
            ${noMenu && 'display: none;'};
            
            @media (max-width: ${style(mode).mobile}) {
               width: 100%;
               justify-content: flex-end;
               flex-direction: row!important;
            }
            
            transition: all .6s ease-in-out;
            
        `;
        const headerRightTitle = css`
            display: flex;
            justify-content: flex-end;
            font-weight: 600;
            flex-shrink: 0;
            @media (max-width: ${style(mode).mobile}) {
               font-size: 16px;
               text-align: right;
            }
            transition: all .6s ease-in-out;
        `;

        const headerRightDescription = css`
            display: flex;
            justify-content: flex-end;
            font-size:14px;
            margin-bottom: 6px;
            @media (max-width: ${style(mode).mobile}) {
               display: none;
            }
            transition: all .6s ease-in-out;
        `;

        const mobileMenu = css`
            margin-left: 15px;
            width:100%;
            justify-content: flex-end;
            color: ${style(mode).color.switchableTextColor};
            align-items: flex-end;
            cursor: pointer;
            display: none;
            @media (max-width: ${style(mode).mobile}) {
                display: flex;
            }
            transition: all .6s ease-in-out;
        `;

        const description = css`
            display: flex;
          height: 100%;
          font-size: 15px;
          justify-content: flex-end;
          align-items: flex-end;
            width:100%;
          padding-bottom: 20px;
          font-weight: 300;
          color: ${style(mode).color.switchableTextColor}!important;
          
          svg {
            position: relative;
            font-size: 8px;
            margin-right: 5px;
            margin-bottom: 10px;
          }
          
          span {
            font-weight: bold;
            font-size: 16px;
          }
        `;

        const avatarStyle = css`
            height: 200px;
            width: 200px;
            border-radius: 200px;
            border: solid 5px #FFFFFF;
            background: ${`url(${avatar})`};
            background-color: #FFFFFF;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            box-shadow: inset #000000 0 0 2px;
            left: calc(50% - 100px);
            position: absolute;
            top: 60px;
            transition: all .6s ease-in-out;

              @media (max-width: ${style(mode).mobile}) {
                // width: 100%;
                display: none;
              }
        `;

        return (
            <div css={header}>
                <div css={headerContent}>
                    <div css={headerLeft}>
                        {
                            children
                        }
                        {
                            totalView && count && totalClicks ? (
                                <div css={socials}>
                                    <div css={subSocialTitle}>
                                        { currentLink && currentLink.title }
                                    </div>
                                    <div css={subSocial}>
                                        <div>{count}</div> {`images across ${links && links.length > 0 && links && links.length} websites`}
                                    </div>
                                    <div css={subSocial}>
                                        <div>{totalClicks}</div> {`clic${totalClicks > 0 && 's'}`}
                                    </div>
                                    <div css={subSocial}>
                                        <div>{totalView}</div> {`profile view${totalView > 0 && 's'}`}
                                    </div>

                                    {
                                        /* links && links.map((link, index) => (
                                           <React.Fragment key={`provider-link-${index}`}>
                                               <SocialIcon url={link} />
                                           </React.Fragment>
                                        )) */
                                    }
                                </div>
                            ) : null
                        }
                    </div>
                    {
                        avatar && (
                            <div css={avatarStyle} />
                        )
                    }
                    {
                        currentLink && (
                            <div css={headerRight}>
                                <div css={headerRightTitle}>
                                    { currentLink && currentLink.title }
                                </div>
                                <div css={headerRightDescription}>
                                    { currentLink && currentLink.description }
                                </div>
                                {/* <div css={mobileMenu}>
                                    <FontAwesomeIcon icon={faBars} onClick={() => this.setState({visibleMenu : true})} />
                                </div> */ }

                                {
                                    this.state.visibleMenu && (
                                        <LateralMenu mode={mode} onClose={() => this.setState({visibleMenu : false})} title={currentLink && currentLink.title}>
                                            <div >
                                                { currentLink && currentLink.description }
                                            </div>
                                            <div>
                                                { menu }
                                            </div>
                                            <div css={dark}>
                                                Dark theme: <span css={modeOption(mode)} onClick={switchTheme}>{(mode === 'dark') ? 'Enabled' : 'Disabled'}</span>
                                            </div>
                                        </LateralMenu>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

Header.contextType = Theme;