import React from "react";
import {
    counterView, editAction,
    hiddenMobile,
    loaded,
    loader,
    siteLink,
    doubleStats,
    doubleStatsPart
} from "../pages/Showroom/showroom.style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showCount, truncateString} from "../helper";
import {Link} from "react-scroll";
import Button from "./button.component";
import {faPen} from "@fortawesome/free-solid-svg-icons/faPen";

const ShowroomMenu = ({
                          mode,
                          currentLink,
                          websites,
                          totalClicked,
                          linkId,
                          goToLink,
                          target
                         }) => {

    return (
        <>
            {currentLink && (
                <div css={hiddenMobile({mode})}>
                    <div css={counterView({mode})}>{showCount(currentLink.viewed) || 0} view{currentLink.viewed > 1 ? 's' : ''}</div>
                </div>
            )}
            <div css={doubleStats(mode)}>
                <div css={doubleStatsPart(mode)}>
                    {
                        websites && !!websites.length && websites.slice(0, Math.floor(websites.length / 2)).map((website, index) => {
                            return(
                                <div css={siteLink({mode})} key={`website-${index}`}>
                                    <Link
                                        to={index === 0 ? 'top' : `${website.source}-${index}`}
                                        smooth={true}
                                        offset={-150}
                                        duration={500}
                                        spy={true}
                                        isDynamic={true}
                                        key={`website-${index}`}
                                        onClick={() => {
                                            goToLink(target === index ? null : index)
                                        }}
                                    >
                                        {showCount(website.source, website.url)} ({website.clicked} clic{website.clicked > 1 ? 's' : ''})
                                    </Link>
                                    <div css={loader({mode})}>
                                        <div css={loaded({mode})} style={{
                                            width: `${website.clicked > 0 ? `${(Math.floor((website.clicked * 100) / totalClicked))}%` : '2px'}`,
                                        }} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div css={doubleStatsPart}>
                    {
                        websites && !!websites.length && websites.slice(Math.ceil(websites.length / 2), websites.length).map((website, index) => {
                            return(
                                <div css={siteLink({mode})} key={`website-right-${index}`}>
                                    <Link
                                        to={index === 0 ? 'top' : `${website.source}-${index}`}
                                        smooth={true}
                                        offset={-150}
                                        duration={500}
                                        spy={true}
                                        isDynamic={true}
                                        key={`website-${index}`}
                                        onClick={() => {
                                            goToLink(target === index ? null : index)
                                        }}
                                    >
                                        {showCount(website.source, website.url)} ({website.clicked} clic{website.clicked > 1 ? 's' : ''})
                                    </Link>
                                    <div css={loader({mode})}>
                                        <div css={loaded({mode})} style={{
                                            width: `${website.clicked > 0 ? `${(Math.floor((website.clicked * 100) / totalClicked))}%` : '2px'}`,
                                        }} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
            <a css={editAction(mode)} href={`/create/${linkId}`}>
                <Button mini>
                    <FontAwesomeIcon icon={faPen} /> Edit page
                </Button>
            </a>
        </>

    )
}


export default ShowroomMenu;
