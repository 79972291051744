import React from "react";
import { css } from '@emotion/react';
import {style} from "../styles/common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTag} from "@fortawesome/free-solid-svg-icons/faTag";

const tagContent = css`       
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  background: ${style(true).color.violet};
  color: #FFFFFF;
  border-radius:  ${style(true).mainRadius};
  margin-right: 8px;
  
  &:last-child {
    margin-right: 0;
  }
  svg {
    font-size: 10px;
  }
  @media (max-width: ${style(true).mobile}) {
    font-size: 11px;
  }
`;

const Tag = (props) => {
    const { children } = props

    return (
        <div css={tagContent}>
            <FontAwesomeIcon icon={faTag} /> {children}
        </div>
    )
}


export default Tag;
