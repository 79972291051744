import React, { forwardRef, useContext } from 'react';
import { style } from '../styles/common'
import { css } from '@emotion/react'

import Theme from "../theme"
import Field from "./field.component";
import Create from "../pages/Create/create.component";
import * as themedStyle from "react-social-icons";
import {faArrowAltCircleRight} from "@fortawesome/free-regular-svg-icons/faArrowAltCircleRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const inputContainer = css`
  margin-top: 25px;
  width: 100%;
`;

const inputContent = css`
  width: 100%;
  display: flex;
`

const error = (props) => {
  const darkMode = props.mode === "dark";
  const themedStyle = style(darkMode);
  return(css`
    width: 100%;
    min-height: 30px;
    height: 39px;
    margin-top: -1px;
    align-items: center;
    font-style: italic;
    display: flex;
    color: ${themedStyle.color.lightRed};
    font-size: 14px;
    font-weight: 400;
  `);
}

const indicatorStyle = (props) => {
  const darkMode = props.mode;
  const themedStyle = style(darkMode);
  return(css`
    font-style: italic;
    color: ${themedStyle.color.switchableTextColor};
    font-size: 13px;
    margin-bottom: 20px;
    font-weight: 400;
    
    svg {
      margin-right: 10px;
    }
    
  `);
}

const input = (props) => {
  const darkMode = props.mode;
  const themedStyle = style(darkMode);

  return css`
    border: solid 1px ${props.isErrored ? themedStyle.color.lightRed : themedStyle.color.stroke};
    background: ${themedStyle.color.inputBackground};
    color: ${themedStyle.color.switchableTextColor};
    border-top-left-radius: ${props.prefix ? "0px" : themedStyle.mainRadius};
    border-bottom-left-radius: ${props.prefix ? "0px" : themedStyle.mainRadius};
    border-top-right-radius: ${props.suffix ? "0px" : themedStyle.mainRadius};
    border-bottom-right-radius: ${props.suffix ? "0px" : themedStyle.mainRadius};
    height: 35px;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 5px;
    
    &:focus {
      outline: none;
    }
  `;
}

const side = (props) => {
  const themedStyle = style(props.mode);

  return css`
    display: flex;
    align-items: center;
    justify-content: ${props.left ? "flex-end" : "flex-start"};
    padding: 0 20px;
    height: 39px;
    margin-top: -1px;
    min-width: 20px;
    margin-right: ${props.left ? -1 : 0}px;
    margin-left: ${props.right ? -1 : 0}px;
    white-space: nowrap;
    width: 300px;
    border: solid 1px ${props.isErrored ? themedStyle.color.lightRed : themedStyle.color.stroke};
    background: ${themedStyle.color.violet};
    color: ${themedStyle.color.switchableTextColor};
    border-top-left-radius: ${props.left ? themedStyle.mainRadius : "0px"};
    border-bottom-left-radius: ${props.left ? themedStyle.mainRadius : "0px"};
    border-top-right-radius: ${props.right ? themedStyle.mainRadius : "0px"};
    border-bottom-right-radius: ${props.right ? themedStyle.mainRadius : "0px"};
  `;
}


const Input = forwardRef(({
  title,
  prefix,
  suffix,
  errorText,
  isErrored,
    indicator,
                            noInput,
  ...inputProps
}, ref) => {
  const { mode } = useContext(Theme);


  return (
    <div css={inputContainer}>
      {title && <Field>{title}</Field>}
      {indicator && <div css={indicatorStyle({mode})}><FontAwesomeIcon icon={faArrowAltCircleRight} />{indicator}</div>}

      <div css={inputContent}>
        {prefix && <div css={side({ isErrored, mode, left: true })}>{prefix}</div>}
        {
          !noInput && <input
              ref={ref}
              type="text"
              css={input({ isErrored, mode, suffix, prefix })}
              {...inputProps}
          />
        }
        {suffix && <div css={side({ isErrored, mode, right: true })}>{suffix}</div>}
      </div>

      {isErrored && errorText && <div css={error({ mode })}>*{errorText}</div>}
    </div>
  )
})


Input.defaultProps = {
  errorText: "This field is required",
}

export default Input;
