import React, { Component } from 'react';
import { css, keyframes } from '@emotion/react'
import {style} from "../styles/common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import Theme from "../theme";

export default class Modal extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    componentDidMount() {
        document.body.style.overflowY = "hidden";
    }
    componentWillUnmount() {
        document.body.style.overflowY = "auto";
    }

    render() {
        const { children, onClose } = this.props;
        const { mode } = this.context;

        const modalContainer = () => css`
          width: 100%;
          height: 100%;
          display: flex;
          position: fixed;
          align-items: center;
          justify-content: center;
          z-index: 201;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, .8);
          
          @media (max-width: ${style(mode).mobile}) {
            padding-top: 20px;
            padding-bottom: 20px;
            margin-bottom: 20px;
          }
        `

        const modalContainerClose = () => css`
          width: calc(100% + 40px);
          display: flex;
          justify-content: flex-end;
        `

        const modalScrollable = () => css`
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          width: 100%;
        `

        const modalContainerCloseContent = () => css`
          background: ${style(mode).color.violet};
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 2px #FFFFFF;
          right: 19px;
          margin-top: -36px;
          border-radius: 40px;
          cursor: pointer;
          box-shadow: ${style(mode).shadow};
          overflow-y: auto;
          overflow-x: hidden;
          transition: all .2s ease-in-out;
          
          &:hover{
            background: ${style(mode).color.lightViolet};
            transition: all .2s ease-in-out;
          }
          
        `

        const modalContainerContent = () => css`
          width: 80%;
          max-width: 800px;
          min-height: 400px;
          max-height: 80%;
          display: flex;
          flex-direction: column;
          z-index: 201;
          left: 0;
          top: 0;
          color: ${style(mode).color.switchableTextColor};
          background: ${style(mode).color.switchableContentColor};
          border-radius: ${style(mode).mainRadius};
          padding: 16px;
          box-shadow: ${style(mode).shadow};

          @media (max-width: ${style(mode).mobile}) {
            width: 70%;
            margin-bottom: 20px;
          }
        `

        return (
            <div css={modalContainer}>
                <div css={modalContainerContent}>
                    <div css={modalContainerClose}>
                        <div css={modalContainerCloseContent} onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    <div css={modalScrollable}>
                    {
                        children
                    }
                    </div>
                </div>
            </div>
        )
    }
}

Modal.contextType = Theme;