import algoliasearch from "algoliasearch";
import algolia from "algoliasearch";
import { ALGOLIA_APPID, ALGOLIA_SEARCHID } from "../const";


const prefix = "prod_";

export const client = algoliasearch(ALGOLIA_APPID, ALGOLIA_SEARCHID);
export const indexTag = client.initIndex(`${prefix}TAGS`);
export const indexLink = client.initIndex(`${prefix}LINKS`);


export const searchTag = async (value = "") => {
  if (value.length < 2) {
    return [];
  }

  const result = await indexTag.search(value, {
    hitsPerPage: 10,
  });

  return result.hits;
}


export const getRelatedLinks = async (tagFilters = []) => {
  const result = await indexLink.search("", {
    hitsPerPage: 10,
    tagFilters: [tagFilters],
  });

  return result.hits;
}