import React, { useContext } from 'react';
import { style } from '../styles/common'
import { css } from '@emotion/react'

import theme from '../theme';
import Field from "./field.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight} from "@fortawesome/free-regular-svg-icons/faArrowAltCircleRight";


const inputContainer = css`
    width: 100%;
    display: flex;
    margin-top: 25px;
    flex-direction: column;
`;

const error = props => css`
    width: 100%;
    min-height: 30px;
    align-items: center;
    font-style: italic;
    display: flex;
    color: ${style(props.mode === "dark").color.lightRed};
    font-size: 14px;
    font-weight: 300;
`;

const input = (props) => {
  const darkMode = props.mode;
  const themedStyle = style(darkMode);

  return css`
    border: solid 1px ${props.isErrored ? themedStyle.color.lightRed : themedStyle.color.stroke};
    background: ${themedStyle.color.inputBackground};
    color: ${themedStyle.color.switchableTextColor};
    border-radius: ${themedStyle.mainRadius};
    min-height: 60px;
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    padding: 5px;
    resize: none;
    
    &:focus {
        outline: none;
        border: solid 1px ${props.isErrored ? themedStyle.color.lightRed : themedStyle.color.lightGreen};
    } 
  `;
};

const indicatorStyle = (props) => {
  const darkMode = props.mode;
  const themedStyle = style(darkMode);
  return(css`
    font-style: italic;
    color: ${themedStyle.color.switchableTextColor};
    font-size: 13px;
    margin-bottom: 20px;
    font-weight: 400;
    
    svg {
      margin-right: 10px;
    }
    
  `);
}


const Textarea = (props) => {
  const {
    title,
    errorText,
    isErrored,
    indicator,
    ...textareaProps
  } = props;
  const { mode } = useContext(theme);

  return (
    <div css={inputContainer}>
      {title && <Field>{title}</Field>}
      {indicator && <div css={indicatorStyle({mode})}><FontAwesomeIcon icon={faArrowAltCircleRight} />{indicator}</div>}

      <textarea css={input({ mode, isErrored })} {...textareaProps} />
      {isErrored && errorText && <div css={error({ mode })}>*{errorText}</div>}
    </div>
  )
}


Textarea.defaultProps = {
  errorText: "This field is required"
}


export default Textarea;
