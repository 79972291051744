import React from "react";


const Checkbox = (props) => {
  const { children, ...inputProps } = props;

  return (
    <>
      <input
        type="checkbox"
        {...inputProps}
      />
      {children}
    </>
  )
}


export default Checkbox;
