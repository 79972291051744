import React, {Component, useContext} from 'react';
import { style } from '../styles/common'
import { jsx, css, keyframes, Global } from '@emotion/react'
import theme from "../theme";
import Theme from "../theme";

export default class Title extends Component {

    render() {
        const { children } = this.props;
        const { mode } = this.context;
        const themedStyle = style(mode);
        const field = css`
          font-size: 16px;
          line-height: 150%;
          font-weight: 500;
          color: ${themedStyle.color.switchableTextColor};
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        `;

        return (
            <div css={field}>
                { children }
            </div>
        )
    }
}
Title.contextType = Theme;