import React, { Component } from 'react';
import { css } from '@emotion/react'
import { truncateString } from '../helper';
import {style} from "../styles/common";

export default class Description extends Component {

    render() {
        const { white, max, content, darkMode } = this.props;

        const description = css`
            font-size: 18px;
            font-style: italic;
            color: ${white ? "#FFFFFF" : "inherit"};
            text-align: left;
            
            @media (max-width: ${style(darkMode).mobile}) {
                font-size: 14px;
              margin-top: 10px;
                color: ${style(darkMode).color.switchableTextColor};
            }
        `;

        return (
            <div css={description}>
               {truncateString(content, max).replace( /  +/g, ' ' )}
            </div>
        )
    }
}
