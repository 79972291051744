import React, { Component } from 'react';
import { style } from '../styles/common'
import { jsx, css, keyframes, Global } from '@emotion/react'

export default class Title extends Component {

    render() {
        const { max, white, content, darkMode } = this.props;

        const title = css`
            font-size: 20px;
            font-weight: 600;
            line-height: 150%;
            width: 100%;
            color: ${white ? "#FFFFFF" : "inherit"};
           
            @media (max-width: ${style(darkMode).mobile}) {
              color: ${style(darkMode).color.switchableTextColor};
              text-align: left;
              font-size: 18px;
              margin-top: 30px;
              //margin-bottom: 10px;
            }
        `;

        let displayContent = content

        if (max && content.length > max) {
            displayContent = `${content.substring(0, max)}...`
        }

        return (
            <div css={title}>
                { displayContent }
            </div>
        )
    }
}
