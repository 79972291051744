import React, { Component } from 'react';
import { style } from '../styles/common';
import { css } from '@emotion/react'
import Button from "./button.component";

export default class Disclaimer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        document.body.style.overflowY = "hidden";
    }
    componentWillUnmount() {
        document.body.style.overflowY = "auto";
    }

    render() {

        const { darkMode } = this.props;

        const disclaimer = css`
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
            z-index: 10000;
            background: rgba(0, 0, 0, .9);
        `

        const disclaimerContent = css`
            width: 80%;
            max-width: 500px;
            top: 0;
            left: 0;
            border-radius: ${style(darkMode).mainRadius};
            box-shadow: ${style(darkMode).shadow};
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 20px;
        `


        const disclaimerContentText = css`
            width: 100%;
            color: ${style(darkMode).color.darkGrey};
            font-weight: 600;
        `

        const disclaimerContentButton = css`
            margin-top: 16px;
        `

        const { } = this.props;
        const { } = this.state;

        return (
            <div css={disclaimer}>
                <div css={disclaimerContent}>
                    <h1>Blanquette</h1>
                    <div css={disclaimerContentText}>
                        This page contains pornographic elements.<br />
                        To access it, you must certify that you are over 18 years old.
                    </div>
                    <div css={disclaimerContentButton}>
                        <Button type={"danger"} label={"I'am over 18"} onClick={() => {
                            window.localStorage.setItem('acceptedBlanquetteCondition', true)
                            window.location.reload()
                        }}/>
                    </div>
                </div>
            </div>
        )
    }
}
