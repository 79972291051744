import React from 'react';
import * as styles from '../styles/common'
import { css, keyframes } from '@emotion/react'
import Video from "./video.component";
import ImageLoader from "./imageLoader.component";
import {style} from "../styles/common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";


export const gifsExtensionsArray = ['gif', 'gifv'];
export const safeImageUrl = { url: "https://www.severnedgevets.co.uk/sites/default/files/styles/medium/public/guides/kitten.png" };
export const safeVideoUrl = { url: "https://i.imgur.com/xMoQNSM.gifv" };



const loadAnimation = keyframes`
  from {
    left: -150px;
  }
  to   {
    left: 100%;
  }
`

const loadingImage = props => css`
  width: 100%;
  height: 100%;
  min-height: 280px;
  background: ${style(props.darkMode ? 'dark' : 'light').color.mainBackground};
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #FFFFFF, 50%, transparent 100%);
    animation: ${loadAnimation} 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
  transition: all .1s ease-in-out;
`
const externalContainer = props => css`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  margin-left: 10px;
  
`
const externalIcon = props => css`
  position: absolute;
  margin-top: 0;
  margin-right: 0;
  background: ${style(true).color.violet};
  border-top-right-radius: ${style(true).mainRadius};
  border-bottom-left-radius: ${style(true).mainRadius};
  padding: 8px;
  font-size: 12px;
  color: #FFFFFF;
  opacity: .8;
  z-index: 20;
  
`

const BackgroundImage = props => css`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props.style.mainRadius};
  background-position: center;
  background-size: cover;
  box-shadow: ${props.style.shadow};

  min-width: 120px;
  margin-left: 10px;
  height: auto;
  margin-right: 10px;
  box-shadow: ${props.style.shadow};
  border-radius: ${props.style.mainRadius};
  cursor: pointer;
  max-width: 100%;
  transition: all .1s ease-in-out;

  &:hover {
    transform: scale(1.05) rotate(1deg);
    box-shadow: ${props.style.shadowHover};
    transition: all .1s ease-in-out;
  }
`


const MediaViewer = ({
  media,
  embedCss,
  embedVideoCss,
  kittenMode,
  videoKey,
  noBackground,
  embedImageStyle,
  onClick,
  darkMode,
  external
}) => {
  const style = styles.style(darkMode);
  const targetImage = (media.previewImageUrl || media.url);
  const targetVideo = (media.previewImageUrl || media.url);
  const imageErrored = !targetImage;

  if (gifsExtensionsArray.includes(media.url.split('.').pop()) && media.url.includes('imgur')) {
    return (
      <div css={embedCss}>
        {!window.localStorage.getItem('acceptedBlanquetteCondition') && (
          <Video
            key={videoKey}
            url={`https://imgur.com/${targetVideo.substring(targetVideo.lastIndexOf('/') + 1).split('.').slice(0, -1).join('.')}.mp4`}
            embedVideoCss={embedVideoCss}
          />
        )}
      </div>
    )
  } else {
    return (
        <>
            { media.previewImageUrl && <div css={externalContainer}>
                <div css={externalIcon}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                </div>
            </div>}
            {
                noBackground ? (
                    <a href={media.url} target="_blank" rel="noreferrer" replace="true" onClick={onClick}>
                        {/*<img src={targetImage} css={embedImageStyle}/>*/}
                        <ImageLoader
                            src={targetImage}
                            errored={imageErrored}
                            onLoading={() => <div css={loadingImage({ style, darkMode })} />}
                            onLoaded={(src) => <img alt="" src={src} css={embedImageStyle} />}
                            onErrored={(label) => <div css={BackgroundImage({ style })}>{label}</div>}
                        />
                    </a>
                ) : (
                    <div
                        role="button"
                        css={embedCss}
                        onClick={onClick}
                        style={{
                            background: noBackground ? 'inherit' : `url('${targetImage}')`
                        }}
                    />
                )
            }
        </>
    )
  }
}


export default MediaViewer;
