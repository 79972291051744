import React, { Component } from 'react';
import { renderColor, style } from '../styles/common'
import { css } from '@emotion/react'
import {renderRandomBackground} from "../image";
import bg from '../images/default-bg.jpg';
import gift from '../images/gift.jpg';
import {parseProviderUrl} from "../helper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import defaultAvatar from '../images/default-blanquette.png';
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";
import {faRedditSquare} from "@fortawesome/free-brands-svg-icons/faRedditSquare";
import {faSnapchatSquare} from "@fortawesome/free-brands-svg-icons/faSnapchatSquare";
import {faTwitch} from "@fortawesome/free-brands-svg-icons/faTwitch";
import {faTwitterSquare} from "@fortawesome/free-brands-svg-icons/faTwitterSquare";
import {faImage} from "@fortawesome/free-regular-svg-icons/faImage";

export default class GeometricalStark extends Component {
    constructor(props) {
        super(props)

        this.state = {
            randomImage: false
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.randomImage && nextProps.providerData && nextProps.providerData.medias && nextProps.providerData.medias.length > 0){
            this.setState({randomImage : renderRandomBackground( nextProps.providerData.medias)})
        }
    }


    render() {
        const knownProviders = [
            { name: 'instagram', icon: faInstagram },
            { name: 'reddit', icon: faRedditSquare },
            { name: 'snapchat', icon: faSnapchatSquare },
            { name: 'twitch', icon: faTwitch },
            { name: 'twitter', icon: faTwitterSquare },
        ]

        const { children, type, even, providerData, noShadow, noHeader, reverse, fill, darkMode, rootData, text, isReport, noAvatar } = this.props;

        const shape = css`
            width: 100%;
            display: flex;
            z-index: 2;
            position: relative;
            flex-direction: column;
        `;

        const textStyle = css`
          width: calc(100% - 50px);
            display: flex;
            z-index: 2;
            position: absolute;
            color: #FFFFFF;
          font-size: 16px;
          font-weight: 300;
          margin-left: 50px;
          margin-top: 50px;

          @media (max-width: ${style(darkMode).mobile}) {
            margin-left: 50px;
            margin-top: 12px;
            font-size: 14px;
            width: calc(100% - 50px);
          }
        `;

         const shapeTexts = css`
            width: calc(100% - 180px);
            display: flex;
            flex-direction: column;
            font-weight: 600;
            ${!noShadow && 'text-shadow: 0 0px 9px black;'};
            
            @media (max-width: ${style(darkMode).mobile}) {
                width: calc(100% - 70px);
            }
        `;

         let displayAvatar = ((providerData && providerData.icon && new DOMParser().parseFromString(providerData.icon, "text/html").documentElement.textContent) || (rootData && rootData.imageUrl && new DOMParser().parseFromString(rootData.imageUrl, "text/html").documentElement.textContent)) || defaultAvatar;
         if (displayAvatar) {
            displayAvatar = displayAvatar.replace(/\(/g, '%28').replace(/\)/g, '%29').replace(' ','%20');
         }
        const avatar = css`
            width: 160px;
            height: 160px;
            border-radius: 160px;
            border: solid 5px ${style(darkMode).color.switchableContentColor};
            position: absolute;
            background: ${`url(${displayAvatar})`};
            background-color: #FFFFFF;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            box-shadow: inset #000000 0 0 2px;
            margin-left: calc(100% - 170px);
            margin-top: 30px;
            @media (max-width: ${style(darkMode).mobile}) {
                border: solid 3px ${style(darkMode).color.switchableContentColor};
                width: 90px;
                height: 90px;
                border-radius: 90px;
                margin-top: -5px;
                margin-left: calc(100% - 90px);
            }
        `;

         const avatarProvider = (provider) => css`
            width: 160px;
            height: 160px;
            border-radius: 160px;
            border: solid 5px ${style(darkMode).color.switchableContentColor};
            position: absolute;
            background-color: #FFFFFF;
            box-shadow: inset #000000 0 0 2px;
            margin-left: calc(100% - 170px);
            margin-top: 30px;
            color: ${style(darkMode).color.violet};
            display: flex;
            text-transform: uppercase;
            justify-content: center;
            align-items: center;
            font-size: 80px;
            @media (max-width: ${style(darkMode).mobile}) {
                border: solid 3px ${style(darkMode).color.switchableContentColor};
                width: 90px;
                height: 90px;
                border-radius: 90px;
                margin-top: -5px;
                font-size: 42px;
                margin-left: calc(100% - 90px);
            }
        `;

        const computedUrl = ((providerData && providerData.banner) || this.state.randomImage || gift || bg).replace(/\(/g, '%28').replace(/\)/g, '%29').replace(' ','%20');
        const computedBg = (parseProviderUrl(providerData && providerData.url) === "amazon" ? gift: bg).replace(/\(/g, '%28').replace(/\)/g, '%29').replace(' ','%20');
        const shapeChild = css`
            background: ${providerData && providerData.banner !== '' ? `linear-gradient(
              rgba(0, 0, 0, 0.6), 
              rgba(0, 0, 0, 0.6)
            ), url(${computedUrl})`: reverse ? '#FFFFFF': `url(${computedBg})`};
            background-color: ${providerData && providerData.banner !== '' ? `#000000`: renderColor(type, darkMode).background};
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: absolute;
            border-top-right-radius: ${isReport ? 0 : style(darkMode).mainRadius};
            font-size: inherit;
            ${!reverse && 'height: 230px;'}
            ${reverse ? 'min-height: 70px;' : 'min-height: 160px;'}
            width: ${fill ? '100%' : (reverse ? '120%' : '120%')};
            ${reverse ? 'margin-left: -34px;' : 'margin-left: -34px;'}
            margin-top:  ${reverse ? '10px' : '-80px'};
            ${reverse && `box-shadow:${style(darkMode).shadow};`}
            ${reverse && `padding-top: 10px;`}
            ${reverse && `cursor: pointer;`}
            transform: rotate(${even ? '-5' : '-5'}deg);
            display: flex;
            flex-direction: column;
             ${!reverse && `pointer-events: none;`}
            color: ${reverse ? `#000000!important` : 'transparent'};
            z-index: -1;
            overflow: hidden;
           
            &:after {
                content: '';
            }
            
            svg {
                position: absolute;
                margin-left: 5px;
                color: ${style(darkMode).color.violet};
                transition: all .3s ease-in-out;
            }
            
            &:hover {
                background-color: ${reverse ? style(darkMode).color.violet: 'inherit'};
                margin-top: ${reverse ? '-5px': 'inherit'};
                font-weight: ${reverse ? 'bold': 'inherit'};
                padding-top: ${reverse ? '10px': 'inherit'};
                color: ${reverse ? '#FFFFFF': 'inherit'};
                box-shadow: 0 0px 9px rgba(0, 0, 0, .1);
                transition: all .3s ease-in-out;
                svg {
                    margin-left: 10px;
                    color: #FFFFFF;
                    transition: all .3s ease-in-out;
                }
                
            }
                
            @media (max-width: ${style(darkMode).mobile}) {
                height: calc(100% * 1.4);
                max-height: 200px!important;
                ${reverse && 'margin-left: 10px;'}
                margin-top: ${reverse ? '0' : '-100px'};
            }
            transition: all .3s ease-in-out;
        `;

        return (
            <div css={shape}>
                {
                    text && (
                        <div css={textStyle}>
                            { text }
                        </div>
                    )
                }
                {
                    reverse ? (
                        <>
                            { !noHeader && <div css={shapeChild}>
                                { children }
                            </div> }
                        </>
                    ) : (
                        <>
                            <div css={shapeTexts}>
                                { children }
                            </div>
                            { !noHeader && <div css={shapeChild} /> }
                            {
                                providerData && providerData.icon && (
                                    <div css={avatar} />
                                )
                            }
                            {
                                ((providerData && !providerData.icon) || (!providerData)) && !noAvatar && (
                                    <div css={avatarProvider}>
                                        { (providerData && providerData.source && knownProviders.find(provider => provider.name === providerData.source)) ? (
                                            <FontAwesomeIcon icon={knownProviders.find((provider => provider.name === providerData.source)).icon} />
                                        ) : (
                                           providerData && providerData.source && providerData.source.charAt(0)
                                        )}
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </div>
        )
    }
}
