import firebase from "firebase/app";


const createLink = async (linkData) => {
  if (!linkData) {
    console.error("[createLink] 'linkData' is undefined");
    return;
  }

  const functions = firebase.functions();

  const link = await functions.httpsCallable("createLink")(linkData);

  return link;
}

export default createLink;
