import {css} from "@emotion/react";
import {style} from "../../styles/common";

export const websitesContainer  = (props) => {
    return css`
        display: flex;
        width: 100%;
        margin-top: 40px;
        justify-content: space-between;
        
        @media (max-width: ${style(props.mode).mobile}) {
           margin-top: 60px;
          flex-direction: column;
        }
        transition: all .1s ease-in-out;
    `
}
export const userInfos  = (props) => {
    return css`
      flex-direction: column;
      width: 100%;
      display: flex;
      margin-bottom: 30px;
        
      @media (max-width: ${style(props.mode).mobile}) {
        display: flex;
      }
      transition: all .1s ease-in-out;
    `
}
export const userInfosTitle  = (props) => {
    return css`
        font-size: 22px;
        color: ${style(props).color.switchableTextColor};
        @media (max-width: ${style(props).mobile}) {
         
        }
        transition: all .1s ease-in-out;
    `
}
export const websitesContainerLeft = (props) => {
    return css`
        min-width: 250px;
        display: flex;
        flex-direction: column;
        height: calc(100% + 180px);
        position: sticky;
        top: 140px;
      
        @media (max-width: ${style(props.mode).mobile}) {
           display: none;
        }
        transition: all .1s ease-in-out;
    `
}
export const websitesContainerLeftContent = (props) => {
    return css`
    width: 100%;
    height: 100%;
    overflow: auto;
`}
export const websitesContainerRight = (props) => {
    return css`
    width: calc(100% - 250px);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% + 180px);
    
    @media (max-width: ${style(props.mode).mobile}) {
       width: 100%;
       margin-left: 0;
    }
`}
export const userHeader = (props) => {
    return css`
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      color: ${style(props.mode).color.switchableTextColor};
      max-width: 1200px;
      margin-bottom: 30px;
    
    @media (max-width: ${style(props.mode).mobile}) {
       
    }
`}
export const userTitle = (props) => {
    return css`
      font-size: 22px;
      margin-bottom: 16px;
      color: ${style(props).color.switchableTextColor};
      font-weight: 600;
    `}
export const tagContainer = (props) => {
    return css`
      display: inline-flex;
      width: 100%;
      
    @media (max-width: ${style(props.mode).mobile}) {
       
    }
`}
export const mobileMenu = (props) => {
    return css`
      display: none;
      margin-top: 20px;
      width: calc(100% - 20px);
      background:  ${style(props).color.switchableContentColor};
      color:  ${style(props).color.switchableTextColor};
      border-radius: ${style(props).mainRadius};
      box-shadow: ${style(props).shadow};
      padding: 10px;
      
    @media (max-width: ${style(props.mode).mobile}) {  
      display: flex;
      flex-direction: column;
    }
`}
export const websitesContainerRelated = (props) => {
    return css`
    width: calc(100% - 250px);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% + 180px);
    
    @media (max-width: ${style(props.mode).mobile}) {
       width: calc(100% + 30px);
       margin-left: -30px;
    }
`}
export const marginEmpty = (props) => {
    return css`
  position: relative;
  height: 50px;
  &:after {
    content: '';
  }
`}
export const avatar = (mode, reducedHeader, swapAvatar) => {
    return css`
    height: ${(swapAvatar)? '140px': '0'};
    width: 100%;
    border-radius: ${style(mode).mainRadius};
    background: ${`url(${swapAvatar})`};
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset #000000 0 0 2px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    visibility: ${(swapAvatar) ? 'visible': 'hidden'};
    @media (max-width: ${style(mode).mobile}) {
        //display: none;
      width: 120px;
      height: 120px;
      border-radius: 120px;
      border: solid 3px #FFFFFF;
      box-shadow: ${style(true).shadow};
    }
    
    transition: all .3s ease-in-out;
`}
export const rows = (props) => {
    return css`
    width: 100%;
    margin-top: 80px;
    margin-left: -5px;
    
    @media (max-width: ${style(props.mode).mobile}) {
       margin-top: 20px;              
    }
   
`}
export const link = (props) => {
    return css`
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 15px;
    text-decoration: underline;
      
      a {
        color: #FFFFFF;
      }
      
     @media (max-width: ${style(props.mode).mobile}) {
       display: none;
    }
`}
export const safeMode = (props) => {
    return css`       
   display: none;
   height: 60px;
   align-items: center;
   font-weight: 600;
   span {
       margin-right: 10px;
   }
`}
export const hiddenMobile = (props) => {
    return css`       
   @media (max-width: ${style(props.mode).mobile}) {
      display: none;
   }
`}
export const menuTitle = (props) => {
    return css`       
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  &:before {
    content: '@';
  }
`}
export const counterView = (props) => {
    return css`       
  font-size: 14px;
  margin-bottom: 10px;
  color: ${style(props.mode).color.violet}
`}
export const menuDescription = (props) => {
    return css`       
   font-weight: 200!important;
   margin-top: 15px;
   font-size: 14px;
   margin-bottom: 15px;
`}
export const margin = (props) => {
    return css`       
    margin-bottom: 80px;
    width: 100%;

  @media (max-width: ${style(props.mode).mobile}) {
    margin-bottom: 20px;
  }
`}
export const comfortPadding = (props) => {
    return css`
  width: 100%;
  min-height: 50px;
  padding-top: 24px;
  @media (max-width: ${style(props.mode).mobile}) {
    min-height: 0;
    padding-top: 0;
  }
`}
export const socialData = (props) => {
    return css`       
   //display: none;
      display: flex;
      margin-top: 16px;
      margin-bottom: 16px;
   width: 100%;
   font-size: 16px;
   padding: 5px;
   justify-content: space-around;
   font-weight: 300;
   border-radius: ${style(props.mode).mainRadius};
   span {
    font-weight: 300;
   }
   
   @media (max-width: ${style(props.mode).mobile}) {
      display: inline-flex;
      margin-top: 0;
     font-size: 13px;
     background: ${props.mode === "dark" ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .05)'};
   }
`}
export const socialItem = (props) => {
    return css`       
    font-weight: 300;
    color: ${style(props.mode).color.switchableTextColor};
      
      svg {
        margin-right: 10px;
      }
`}
export const editAction  = (props) => {
    return css`       
    font-weight: 300;
    color: #FFFFFF;
      
      svg {
        margin-right: 6px;
        font-size: 12px;
      }
`}
export const showdesktop = (props) => {
    return css`       
    @media (max-width: ${style(props.mode).mobile}) {
      display: none;
     }
`}
export const row = (props) => {
    return css`
    margin-top: 30px;
    display: inline-flex;
    min-width: 120px;
    margin-left: 10px;
    height: auto;
    margin-right: 10px;
    box-shadow: ${style(props.mode).shadow};
    border-radius: ${style(props.mode).mainRadius};
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
    transition: all .1s ease-in-out;
    
    &:hover {
        transform: scale(1.05) rotate(${props.isEven ? '-1' : '1'}deg);
        box-shadow: ${style(props.mode).shadowHover};
        transition: all .1s ease-in-out;
    }
`}
export const imageRow  = (props) => {
    return css`
        display: inline-flex;
        min-width: 120px;
        margin-left: 10px;
        height: auto;
        margin-right: 10px;
        box-shadow: ${style(props.mode).shadow};
        border-radius: ${style(props.mode).mainRadius};
        cursor: pointer;
        max-width: 100%;
        transition: all .1s ease-in-out;
        
        &:hover {
            transform: scale(1.05) rotate(${props.isEven ? '-1' : '1'}deg);
            box-shadow: ${style(props.mode).shadowHover};
            transition: all .1s ease-in-out;
        }
    `
}
export const video = (props) => {
    return css`       
   height: 100%;
   border-radius: ${style(props.mode).mainRadius};
   background: #000000;
   max-width: 100%;
`}
export const loader = (props) => {
    return css`
   height: 10px;
   background: rgba(0, 0, 0, .03);
   width: 100%;
   border-radius: ${style(props.mode).mainRadius};
`}
export const loaded = (props) => {
    return css`       
   height: 100%;
   margin-top:4px;
   background: ${style(props.mode).color.violet};
   border-radius: ${style(props.mode).mainRadius};
`}
export const siteLink = (props) => {
    return css`
   margin-bottom: 16px;
   text-transform: capitalize;
      
      a {
        cursor: pointer;
      }
`}
export const doubleStats = (props) => {
    return css`
      width: 100%;
      display: flex;
      flex-direction: column;
      @media (max-width: ${style(props).mobile}) {
        flex-direction: row;
        justify-content: space-between;
      }
`}
export const doubleStatsPart = (props) => {
    return css`
      width: 100%;
      
      @media (max-width: ${style(props).mobile}) {
        padding-right: 20px;
        &:last-child {
          padding-right: 0;
        }
      }
`}
export const websiteTip = (props) => {
    return css`    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
      
    @media (max-width: ${style(props.mode).mobile}) {
      margin-top: ${props.isInfo ? '0px' : '0px'};
      display: inline-flex;
      //min-height: 75px;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column!important;
     }
`}

export const mobileLoader = () => {
    return css`    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
      
    @media (max-width: ${style(true).mobile}) {
      margin-top: 30px;
     }
`}
export const mobileDisplay = (props) => {
    return css`    
    align-items: center;
    justify-content: center;
    width: 100%;
    display: none;
    
     @media (max-width: ${style(props.mode).mobile}) {
      display: flex;
      //margin-bottom: 20px;
      align-items: flex-start!important;
      text-align: left;
      justify-content: flex-start;
      flex-direction: column!important;
     }
`}
export const imgContainer = (props) => {
    return css`    
     margin-top: 50px;
     margin-bottom: 50px;
      margin-left: -10px;
      
`}
export const shareBox = (props) => {
    return css`    
     margin-top: 30px;
`}
export const shareBoxMobile = (props) => {
    return css`    
     margin-top: 0;
      width: 100%;
      display: none;
      @media (max-width: ${style(props).mobile}) {
        margin-bottom: 30px;
        display: flex;
      }
`}
export const separator = (props) => {
    return css`    
      width: 40px; 
    `
}
export const centeredOption = (props) => {
    return css`    
     display: flex;
     align-items: center;
     justify-content: flex-start;
      cursor: pointer;
     
     svg {
        margin-left: 10px;
     }
`}
export const pointer = (props) => {
    return css`    
     cursor: pointer;
`}
export const websitesSharing = (props) => {
    return css`    
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
      
    a {
      color: ${style(props.mode).color.switchableTextColor};    
    }
    
    svg {
        cursor: pointer;
        position: relative;
        &:hover {
            transform: scale(1.5);
            transition: all .2s ease-in-out;
            margin-top: -3px;
        }
        transition: all .2s ease-in-out;
    }
    transition: all .2s ease-in-out;
`}
export const websiteTipContent = (props) => {
    return css`    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    background: ${style(props.mode).color.violet};
    border-radius: ${style(props.mode).mainRadius};
    box-shadow: ${style(props.mode).shadowSoft};
    cursor: pointer;
    color: #FFFFFF;
    transition: all .3s ease-in-out;
    margin-bottom: ${props.medias === 0 ? '50px' : '0'};
    margin-top: ${props.medias === 0 ? '80px' : '0'};
     
    &:hover {
        box-shadow: ${style(props.mode).shadow};
        transition: all .3s ease-in-out;
    }
    
    span {
        font-weight: 600;
        margin-left: 5px;
    }
    
    @media (max-width: ${style(props.mode).mobile}) {
        margin-top: 20px;
        font-size: 16px;
        margin-bottom: 0;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-left: auto;
        margin-right: auto;
    }
`
}
export const reportIntro = css`
          width: 100%;
          font-size: 18px;
          margin-bottom: 20px;
          
          svg {
            margin-right: 10px;
          }
          span {
            font-weight: 600;
          }
        `
export const reportContainer = css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `
export const reportTop = css`
          width: 100%;
          display: flex;
          flex-direction: column;
          
          span {
            font-weight: 600;
          }
        `
export const reportBottom = css`
          width: 100%;
          min-height: 50px;
          display: flex;
          justify-content: flex-end;          
          span {
            font-weight: 600;
          }
        `
export const reportLabel = css`
          font-size: 16px;
          font-weight: 400;
          margin-top: 20px;
          padding-top: 6px;
          padding-bottom: 6px;
          width: 120px;
          margin-bottom: 16px;
          color:${style(true).color.violet};
        `
export const editDate = mode => css`
          font-style: italic;
          display: flex;
          span {
            font-weight: 500;
            display: flex;
            margin-right: 6px;
            color: ${style(mode).color.switchableTextColor};
          }
          color:${style(true).color.violet};
  
          @media (max-width: ${style(true).mobile}) {
           display: flex;
          }
        `
export const userDetailsContainer = css`
          width: 100%;
          display: flex;
          margin-top: 8px;
          justify-content: space-between;
          
          @media (max-width: ${style(true).mobile}) {
            flex-direction: row;
            font-size: 11px;
          }
        `

export const reportIndicator = css`
          font-size: 14px;
          font-weight: 200;
          margin-top: 20px;
        `

export const reportOptions = css`
          width: 100%;
          font-size: 16px;
          
          select {
            border-radius: ${style(true).mainRadius};
            padding:4px;
          }
        `
export const radioStyle = css`
          margin-bottom: 10px;
          display: flex;
          cursor: pointer;
        `
export const reportText = css`
          width: 100%;
          font-size: 16px;
        `
export const reportActions = css`
          width: 100%;
          font-size: 16px;
          display: flex;
          justify-content: flex-end;
        `
export const reportStyle = css`
          width: calc(100% + 40px);
          margin-left: -20px;
          display: inline-flex;
          justify-content: flex-end;
  
          @media (max-width: ${style(true).mobile}) {
            width: calc(100% + 30px);
          }
        `
export const reportStyleContent = css`
          background: ${style(true).color.lightRed};
          color: #FFFFFF;
          border-top-right-radius: ${style(true).mainRadius};
          border-top-left-radius: ${style(true).mainRadius};
          z-index: 10;
          margin-top: -39px;
          position: absolute;
          min-width: 100px;
          display: flex;
          flex-wrap: nowrap;
          flex-shrink: 0;
          align-items: center;
          padding-left: 8px;
          padding-right: 8px;
          font-weight: 600;
          padding-top: 2px;
          padding-bottom: 2px;
          font-size: 12px;
          cursor: pointer;
          transition: all .2s ease-in-out;
          
          &:hover {
            background: ${style(true).color.lightRedHover};
            transition: all .2s ease-in-out;
          }
          
          svg {
            font-size: 10px;
            margin-right: 8px;
          }
          @media (max-width: ${style(true).mobile}) {  
            margin-top: -30px;
            margin-right: 0;
          }
        `
export const relatedStyle = (mode) => css`
          color: ${style(mode).color.switchableTextColor};
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 30px;
          
          svg {
            color: ${style(mode).color.violet};
            margin-left: 10px;
            margin-right: 10px;
          }

          @media (max-width: ${style(mode).mobile}) {
            font-size: 14px;
          }
        `
export const paddingShadow = () => css`
         padding: 20px;
          width: calc(100% - 60px);
        `