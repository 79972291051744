import * as React from 'react';
import emptyImage from './images/empty.png'
export const renderRandomBackground = (medias) => {

  if (medias.find(media => media && media.url || media && media.previewImageUrl)) {
    const imagesOnly = getImages(medias);
    if (imagesOnly.length > 0) {
      return new DOMParser().parseFromString(imagesOnly.length === 1 ? imagesOnly[0] : imagesOnly, "text/html").documentElement.textContent;
    }

  } else {
    const rdmBackgroundIndex = (Math.floor(Math.random() * medias.length))
    if (medias[rdmBackgroundIndex] && medias[rdmBackgroundIndex]) {
      return new DOMParser().parseFromString(medias[rdmBackgroundIndex], "text/html").documentElement.textContent;
    }
  }
  return false
}


export const getImages = (medias) => {
  return medias.map((media) => media.previewImageUrl || media.url)
    .filter((media) => media.toLowerCase().includes(".jp") || media.toLowerCase().includes(".png"));
}

export const arrayifyImages = (providers) => {
  return providers && providers.reduce((acc, provider) => {
    return acc.concat((provider.medias || [])
      .map((media) => media.previewImageUrl || media.url)
      .filter((media) => media.toLowerCase().includes(".jp") || media.toLowerCase().includes(".png")));
  }, [])
}

export const selectRandomImage = (providers) => {
  const images = arrayifyImages(providers);
  return images ? images[Math.floor(Math.random() * images.length)] : null;
}


export const loadImage = (src) => new Promise((resolve) => {
  const image = new Image();

  image.onload = () => resolve(src);
  image.onerror = () => resolve(null);

  image.src = src;
});