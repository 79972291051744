import React, { Component } from 'react';
import firebase from 'firebase/app';
import { Link, Element } from 'react-scroll';
import Header from "../Header/header.component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContextualMenu from "../../components/contextualMenu.component";
import ShareBox from "../../components/shareBox.component";
import {withRouter} from "react-router-dom";
import SEO from '../../components/seo.component';
import {parseProviderUrl, showCount, truncateString} from '../../helper';
import ShowroomLoader from "../../components/showroomLoadingTemplate.component";
import {faTwitter} from "@fortawesome/free-brands-svg-icons/faTwitter";
import {faFacebookF} from "@fortawesome/free-brands-svg-icons/faFacebookF";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import {faReddit} from "@fortawesome/free-brands-svg-icons/faReddit";
import Loader from "../../components/loader.component";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons/faEnvelope";
import {renderRandomBackground} from "../../image";
import HeaderSticky from "../Header/headerSticky.component";
import Theme from "../../theme";
import defaultAvatar from '../../images/default-blanquette.png';
import {
    avatar,
    centeredOption, userDetailsContainer, editDate,
    shareBox,
    shareBoxMobile,
    separator,
    websitesContainer,
    userInfos,
    userInfosTitle,
    websitesContainerLeft,
    websitesContainerRight,
    userHeader,
    userTitle,
    tagContainer,
    mobileMenu,
    websitesSharing, websitesContainerRelated, pointer,
} from "./showroom.style";
import { getRelatedLinks } from '../../services/algolia';
import BarMenu from "../../components/barMenu.component";
import RelatedProfiles from "../../components/relatedProfiles.component";
import ShowroomMenu from "../../components/showroomMenu.component";
import ContactModal from "../../components/contactModal.component";
import ReportModal from "../../components/reportModal.component";
import ProviderData from "../../components/providerData.component";
import {faPinterest} from "@fortawesome/free-brands-svg-icons/faPinterest";
import Moment from "react-moment";
import Tag from "../../components/tag.component";
import {faSun} from "@fortawesome/free-regular-svg-icons/faSun";
import {faMoon} from "@fortawesome/free-regular-svg-icons/faMoon";

const masonryOptions = {
    transitionDuration: 0,
    percentPosition: true,
    columnWidth: 50
}

class Showroom extends Component {
    constructor(props) {
        super(props)

        this.imgurTag = null;
        this.facebookTag = null;

        this.state = {
            websites: [],
            even: false,
            link: null,
            mediaCount: 0,
            totalClicked: 0,
            target: null,
            imageUrl: null,
            reason: null,
            reportDescription: null,
            contactDescription: null,
            contactEmail: null,
            providerUrl: null,
            providerId: null,
            imageTarget: null,
            selectedProvider: null,
            errorField: false,
            successSend: false,
            related: [],
            reducedHeader: false,
            swapAvatar: false,
            block: false,
            mainLoader: true,
            reportModal: false,
            contactModal: false,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if ((nextState.reportDescription !== this.state.reportDescription) || (nextState.contactDescription !== this.state.contactDescription) || (nextState.contactEmail !== this.state.contactEmail)) {
            return false;
        }
        return true;
    }

    refreshData = async (id) => {
        const db = firebase.firestore();
        const linkSnapshot = await db.collection('links').doc(id).get();
        const providerSnapshots = await db.collection('links')
            .doc(id)
            .collection('providers')
            .get();

        const websitesResult = providerSnapshots.docs.map((providerDoc) => providerDoc.data())
        const link = linkSnapshot.data();
        const related = await getRelatedLinks(link.tags || []);
        this.setState({
            link,
            related,
            websites: websitesResult,
            mainLoader: false
        }, () => {

            let mediaCount = 0;
            const totalClicked = websitesResult.map(website => website.clicked).reduce((a, b) => a + b, 0);

            if (websitesResult && websitesResult.length > 0) {
                websitesResult.forEach(w => {
                    mediaCount = w.medias ? (mediaCount + w.medias.length) : mediaCount;
                })
                this.setState({
                    mediaCount,
                    totalClicked
                })
            }

            window.requestAnimationFrame(() => {
                if (this.imgurTag) {
                    this.imgurTag.parentNode.removeChild(this.imgurTag);
                }

                this.imgurTag = document.createElement('script');
                this.imgurTag.src = "//s.imgur.com/min/embed.js";
                this.imgurTag.type = "text/javascript";
                this.imgurTag.async = true;

                if (this.facebookTag) {
                    this.facebookTag.parentNode.removeChild(this.facebookTag);
                }

                this.facebookTag = document.createElement("script");
                this.facebookTag.src = "https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v9.0";
                this.facebookTag.type = "text/javascript";
                this.facebookTag.async = true;

                document.body.appendChild(this.imgurTag);
                document.body.appendChild(this.facebookTag);
            });
        });
    }

    handleScroll = () => {
        const scrolled = (document.body.scrollTop || document.documentElement.scrollTop)
        if (scrolled && !this.state.reducedHeader && scrolled > 100) {
            this.setState({reducedHeader: true})
        } else {
            if (scrolled && scrolled < 101) {
                this.setState({reducedHeader: false})
            }
        }
    }


    async componentDidMount() {
        const {id: linkId} = this.props.match.params;
        const functions = firebase.functions();

        await this.refreshData(linkId);
        await functions.httpsCallable("refreshLink")({linkId});
        await this.refreshData(linkId);

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {imageTarget, websites, swapAvatar, imageUrl, block} = this.state;
        if (!imageUrl && !swapAvatar && !imageTarget && websites.length > 0) {
            this.setState({
                block: true,
                imageUrl: imageTarget ? (websites && websites[imageTarget] && [websites[imageTarget].icon]) : (websites && websites.filter(w => parseProviderUrl(w.icon).toLowerCase() !== "amazon").map((provider) => provider.icon))
            });
        } else {
            if (imageUrl.length > 0 && !swapAvatar, !block) {
                this.setState({
                    swapAvatar: renderRandomBackground(imageUrl),
                    imageUrl
                })
            }
        }
    }

    isEven = (value) => {
        return (value % 2 === 0);
    }

    createClickProviderHandler = (linkId, providerId) => () => {
        const functions = firebase.functions();

        return functions.httpsCallable("onClickProvider")({linkId, providerId});
    }

    render() {
        const {id: linkId} = this.props.match.params;
        const {
            link: currentLink,
            websites,
            isEven,
            imageTarget,
            mediaCount,
            totalClicked,
            mainLoader,
            swapAvatar,
            reducedHeader,
            related,
            providerId,
            providerUrl,
        } = this.state;
        const {mode, switchTheme, showReportModal, showContactModal, setContactModal, setReportModal} = this.context;

        const imageUrl = imageTarget ? (websites && websites[imageTarget] && [websites[imageTarget].icon]) : (websites && websites.filter(w => parseProviderUrl(w.icon).toLowerCase() !== "amazon").map((provider) => provider.icon));

        return (
            <>
                <SEO
                    type="article"
                    imageUrl={imageUrl}
                    title={(currentLink && currentLink.title) || undefined}
                    description={(currentLink && currentLink.description) || undefined}
                />

                {showContactModal && (<ContactModal setContactModal={setContactModal}/>)}
                {showReportModal && (
                    <ReportModal
                        providerId={providerId}
                        providerUrl={providerUrl}
                        websites={websites}
                        totalClicked={totalClicked}
                        linkId={linkId}
                        setReportModal={() => setReportModal()}
                        showReportModal={showReportModal}
                    />)
                }

                <Element name={`top`}/>
                <HeaderSticky
                    reducedHeader={reducedHeader}
                    darkMode={mode}
                    menu={<ShowroomMenu mode={mode} currentLink={currentLink} websites={websites}
                                        totalClicked={totalClicked} linkId={linkId}
                                        goToLink={(e) => this.setState({ imageTarget: e })}
                                        target={this.state.target}
                    />}
                />
                <Header
                    reducedHeader={reducedHeader}
                    imgList={imageUrl}
                    currentLink={currentLink}
                    darkMode={mode}
                    links={websites && websites.map((website) => website.url)}
                    count={mediaCount}
                    totalClicks={totalClicked}
                    avatar={swapAvatar}
                    totalView={currentLink && currentLink.viewed}
                    menu={<ShowroomMenu mode={mode} currentLink={currentLink} websites={websites}
                                        totalClicked={totalClicked} linkId={linkId}
                                        goToLink={(e) => this.setState({ imageTarget: e })}
                                        target={this.state.target}
                    />}
                />
                <BarMenu/>
                <div css={websitesContainer({mode, reducedHeader, swapAvatar})}>
                    <div css={websitesContainerLeft({mode, reducedHeader, swapAvatar})}>
                        <ContextualMenu darkMode={mode} reducedHeader={reducedHeader} avatar={swapAvatar}>
                            {
                                mainLoader ? (
                                    <Loader minHeight={300} image/>
                                ) : (
                                    <>
                                        <div css={avatar(mode, reducedHeader, swapAvatar || imageUrl[0] || defaultAvatar)}/>
                                        <ShowroomMenu mode={mode} currentLink={currentLink} websites={websites}
                                                      totalClicked={totalClicked} linkId={linkId}
                                                      goToLink={(e) => this.setState({ imageTarget: e })}
                                                      target={this.state.target}
                                        />
                                    </>
                                )
                            }
                        </ContextualMenu>
                        <div css={shareBox({mode, reducedHeader, swapAvatar})}>
                            <ShareBox darkMode={mode} reducedHeader={reducedHeader}>
                                <div>Share profile</div>
                                <div css={websitesSharing({mode, reducedHeader, swapAvatar})}>
                                    <a target="_blank" href={`https://www.facebook.com/sharer.php?u=https://www.blanquette.io${this.props.location.pathname}`}><FontAwesomeIcon icon={faFacebookF}/></a>
                                    <a target="_blank" href={`https://twitter.com/share?url=https://www.blanquette.io${this.props.location.pathname}&text=${(currentLink && currentLink.description) || 'Check this Blanquette'}&via=Blanquette&hashtags=#blanquette`}> <FontAwesomeIcon icon={faTwitter}/></a>
                                    <a target="_blank" href={`https://pinterest.com/pin/create/bookmarklet/?media=${imageUrl || defaultAvatar}&url=https://www.blanquette.io${this.props.location.pathname}&description=${(currentLink && currentLink.description) || 'Check this Blanquette'}`}><FontAwesomeIcon icon={faPinterest}/></a>
                                    <a target="_blank" href={`https://reddit.com/submit?url=https://www.blanquette.io${this.props.location.pathname}&title=${(currentLink && currentLink.title) || 'Blanquette'}`}><FontAwesomeIcon icon={faReddit}/></a>
                                    <a target="_blank" href={`whatsapp://send?text=${(currentLink && currentLink.description) || 'Check this Blanquette'} https://www.blanquette.io${this.props.location.pathname}`}><FontAwesomeIcon icon={faWhatsapp}/></a>
                                </div>
                            </ShareBox>
                        </div>
                        <div css={shareBox({mode, reducedHeader, swapAvatar})}>
                            <ShareBox darkMode={mode} reducedHeader={reducedHeader}>
                                <div css={centeredOption({mode, reducedHeader, swapAvatar})}
                                     onClick={() => setContactModal()}>
                                    contact us <FontAwesomeIcon icon={faEnvelope}/>
                                </div>
                            </ShareBox>
                        </div>
                    </div>
                    <div css={websitesContainerRight({mode, reducedHeader, swapAvatar})}>
                        {
                            mainLoader ? (
                                <ShowroomLoader iteration={3} mode={mode}/>
                            ) : (
                                <>
                                    <div css={userHeader(mode)}>
                                        <div css={userInfos(mode)}>
                                            <div css={userInfosTitle(mode)}>{currentLink && currentLink.title}</div>
                                            {
                                                (currentLink && currentLink.createdAt && currentLink.createdAt.seconds && (
                                                    <div css={userDetailsContainer}>
                                                        <div css={editDate(mode)}>
                                                            <span>Created</span> <Moment interval={1000} format="DD/MM/YYYY" date={new Date(currentLink.createdAt.seconds * 1000)} />
                                                        </div>
                                                        <div css={editDate(mode)}>
                                                            <span>Last update</span> <Moment interval={1000} fromNow date={new Date(currentLink.updatedAt.seconds * 1000)} />
                                                        </div>
                                                    </div>

                                                ))
                                            }
                                        </div>
                                        <div css={tagContainer}>
                                            {
                                                currentLink && currentLink.tags && currentLink.tags.map((tag) => (
                                                    <Tag>{tag}</Tag>
                                                ))
                                            }
                                        </div>
                                        <div css={mobileMenu(mode)}>
                                            <div css={avatar(mode, reducedHeader, swapAvatar || imageUrl[0] || defaultAvatar)}/>
                                            <ShowroomMenu mode={mode} currentLink={currentLink} websites={websites}
                                                          totalClicked={totalClicked} linkId={linkId}
                                                          goToLink={(e) => this.setState({ imageTarget: e })}
                                                          target={this.state.target}
                                            />
                                        </div>

                                    </div>
                                {
                                    websites && !!websites.length && websites.map((provider, parentIndex) => (
                                        <ProviderData
                                            provider={provider}
                                            mode={mode}
                                            reducedHeader={reducedHeader}
                                            swapAvatar={swapAvatar}
                                            isEven={isEven}
                                            parentIndex={parentIndex}
                                            setReportModal={setReportModal}
                                            linkId={linkId}
                                            createClickProviderHandler={this.createClickProviderHandler}
                                        />
                                    ))
                                }
                                    <div css={shareBoxMobile({mode, reducedHeader, swapAvatar})}>
                                        <ShareBox darkMode={mode} reducedHeader={reducedHeader}>
                                            <div>Share profile</div>
                                            <div css={websitesSharing({mode, reducedHeader, swapAvatar})}>
                                                <a target="_blank" href={`https://www.facebook.com/sharer.php?u=https://www.blanquette.io${this.props.location.pathname}`}><FontAwesomeIcon icon={faFacebookF}/></a>
                                                <a target="_blank" href={`https://twitter.com/share?url=https://www.blanquette.io${this.props.location.pathname}&text=${(currentLink && currentLink.description) || 'Check this Blanquette'}&via=Blanquette&hashtags=#blanquette`}> <FontAwesomeIcon icon={faTwitter}/></a>
                                                <a target="_blank" href={`https://pinterest.com/pin/create/bookmarklet/?media=${imageUrl || defaultAvatar}&url=https://www.blanquette.io${this.props.location.pathname}&description=${(currentLink && currentLink.description) || 'Check this Blanquette'}`}><FontAwesomeIcon icon={faPinterest}/></a>
                                                <a target="_blank" href={`https://reddit.com/submit?url=https://www.blanquette.io${this.props.location.pathname}&title=${(currentLink && currentLink.title) || 'Blanquette'}`}><FontAwesomeIcon icon={faReddit}/></a>
                                                <a target="_blank" href={`whatsapp://send?text=${(currentLink && currentLink.description) || 'Check this Blanquette'} https://www.blanquette.io${this.props.location.pathname}`}><FontAwesomeIcon icon={faWhatsapp}/></a>
                                            </div>
                                        </ShareBox>
                                    </div>
                                </>

                            )
                        }
                        <div css={websitesContainerRelated}>
                            <RelatedProfiles masonryOptions={masonryOptions} mode={mode} related={related} currentLink={currentLink}/>
                        </div>
                        <div css={shareBoxMobile({mode, reducedHeader, swapAvatar})}>
                            <ShareBox darkMode={mode} reducedHeader={reducedHeader}>
                                <div css={centeredOption({mode, reducedHeader, swapAvatar})}
                                     onClick={() => setContactModal()}>
                                    contact us <FontAwesomeIcon icon={faEnvelope}/>
                                </div>
                            </ShareBox>
                            <div css={separator} />
                            <ShareBox darkMode={mode} reducedHeader={reducedHeader}>
                                <div css={centeredOption({mode})}>
                                    <div css={pointer} onClick={switchTheme}>
                                        <FontAwesomeIcon icon={mode === 'dark'? faSun : faMoon} /> {mode === 'dark' ? 'Light' : 'Dark'} theme
                                    </div>
                                </div>
                            </ShareBox>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

Showroom.contextType = Theme;
export default withRouter(Showroom);
