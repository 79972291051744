import React, { Component } from 'react';
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { style } from '../../styles/common';
import { Link } from "react-router-dom";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import LateralMenu from "../../components/lateralMenu.component";
import Theme from "../../theme";
import Header from "./header.component";
import {faCopyright} from "@fortawesome/free-regular-svg-icons/faCopyright";

export default class HeaderSticky extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visibleMenu: false
        }
    }

    render() {
        const { currentLink, switchContent, children, menu } = this.props;
        const { mode, switchTheme } = this.context;
        const header = css`
            background: ${style(mode).color.headerBackground};
            height: 60px;
            color: #000000;
            top: 0;
            left: 0;
          display: flex;
          align-items: center;
            right: 0;
            position: fixed;
            z-index:101;

        `;

        const dark = css`
          width: 100%;
          font-size: 14px;
          span {
            font-weight: 600;
          }
        `

        const headerLeft = css`
            width: calc(100% - 60px);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 5px;
            font-weight: 200;
            font-size: 22px;
          
            a {
              color: ${style(mode).color.switchableTextColor}!important;
              font-weight: 300;
              margin-left: 20px;
            }
          
            
            transition: all .6s ease-in-out;
        `;

        const headerRight = css`
            width: 60px;
            height: 100%;
            display: flex;
            color: ${style(mode).color.switchableTextColor};
            align-items: center;
            justify-content: flex-end;
            padding-right: 30px;
            transition: all .6s ease-in-out;
            
        `;
        const headerRightTitle = css`
            font-weight: 600;
            flex-shrink: 0;
            @media (max-width: ${style(mode).mobile}) {
               font-size: 16px;
               text-align: right;
            }
            transition: all .6s ease-in-out;
        `;

        const headerRightDescription = css`
            font-size:14px;
            @media (max-width: ${style(mode).mobile}) {
               display: none;
            }
            transition: all .6s ease-in-out;
        `;

        const menuItem = css`
            width: calc(100% - 40px);
        `;

        const mobileMenu = css`
            color: ${style(mode).color.switchableTextColor};
            cursor: pointer;
              @media (min-width: ${style(mode).mobile}) {
                display: none;
              }
            transition: all .6s ease-in-out;
        `;

        const description = css`
            display: flex;
          height: 100%;
          font-size: 13px;
          justify-content: flex-start;
          align-items: center;
            width:100%;
          font-weight: 300;
          color: ${style(mode).color.switchableTextColor}!important;
          
          a {
            font-size: 13px;
          }
          svg {
            position: relative;
            font-size: 8px;
            margin-right: 5px;
            margin-bottom: 10px;
          }
          
          span {
            font-weight: bold;
            font-size: 18px;
          }

          @media (max-width: ${style(mode).mobile}) {
            font-size: 13px;
            a {
              font-size: 13px;
              width: 100%;
            }
            svg {
              position: relative;
              font-size: 8px;
              margin-right: 5px;
              margin-bottom: 10px;
            }

            span {
              font-weight: bold;
              font-size: 16px;
            }

          }
        `;

        const modeOption = props => css`
          color: ${props.mode === "dark" ? style(props).color.violet : style(props).color.switchableTextColor};
        `
        return (
            <div css={header}>
                <div css={headerLeft}>
                    <div css={description}>
                        <Link to={"/"}>
                            <span>Blanquette<FontAwesomeIcon icon={faCopyright} /></span>The world first place to find all links from amazing models and websites.
                        </Link>
                    </div>
                </div>
                <div css={headerRight}>
                    <div css={headerRightTitle}>
                        { currentLink && currentLink.title }
                    </div>
                    <div css={headerRightDescription}>
                        { currentLink && currentLink.description }
                    </div>
                    {/*
                    <div css={mobileMenu}>
                        <FontAwesomeIcon icon={faBars} onClick={() => this.setState({visibleMenu : true})} />
                    </div>
                    */}
                    {

                    }
                    {
                        this.state.visibleMenu && (
                            <LateralMenu mode={mode} onClose={() => this.setState({visibleMenu : false})} title={currentLink && currentLink.title}>
                                <div css={menuItem}>
                                    { currentLink && currentLink.description }
                                </div>
                                <div css={menuItem}>
                                    { menu }
                                </div>
                                <div css={dark}>
                                    Dark theme: <span css={modeOption(mode)} onClick={switchTheme}>{mode === 'dark' ? 'Enabled' : 'Disabled'}</span>
                                </div>
                            </LateralMenu>
                        )
                    }
                </div>
            </div>
        )
    }
}

HeaderSticky.contextType = Theme;