import React, { Component } from 'react';
import { css } from '@emotion/react';
import { style } from '../../styles/common';
import Button from "../../components/button.component";
import {faCopyright} from "@fortawesome/free-regular-svg-icons/faCopyright";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Theme from "../../theme";
export default class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        const { mode } = this.context;
        const footer = css`
            background: ${style(mode).color.headerBackground};
            height: 100px;
            bottom: 0;
            width: 100%;
            left: 0;
            font-weight: bold;
            margin-top: -20px;
            z-index: 10;
            box-shadow: ${style(mode).shadowTop};
            transition: all .3s ease-in-out;
        `;
        const footerContainer = css`
            width: calc(100% - 60px);
            display: flex;
            height: 100%;
            align-items: center;
            margin-top:10px;
            justify-content: center;
            margin-left: 30px;
            color: ${style(mode).color.switchableTextColor};
            margin-right: 30px;
            font-weight: 300;            
            
            span {
                margin-left: 5px;
                font-weight: 500;
            }
            
            svg {
                font-size: 10px;
                margin-bottom: 5px;
            }
        `;

        return (
            <div css={footer}>
                <div css={footerContainer}>
                    Powered by <span>BroStark</span><FontAwesomeIcon icon={faCopyright} /> .
                </div>
            </div>
        )
    }
}

Footer.contextType = Theme;
