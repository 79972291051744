export const truncateString = (text = "", maxLength = 100) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }

  return text;
}

export const showCount = (count = 0, url) => {
  let suffix = "";
  let computedValue = count.toString();

  if (count >= 1000000) {
    suffix = "m";
    computedValue = (count / 1000000).toFixed(1);
  } else if (count >= 1000) {
    suffix = "k";
    computedValue = (count / 1000).toFixed(1);
  }

  const [num, decimal] = computedValue.split(".");

  if (decimal === "0") {
    computedValue = num;
  }

  if (`${computedValue}${suffix}` === 'unknown') {
    const provider = parseProviderUrl(url)
    switch (provider) {
      case 'amazon':
        return  `${provider}${suffix} wishlist`;
      default:
        return  `${provider}${suffix}`;
    }
  } else {
    return `${computedValue}${suffix}`;
  }
}

export const parseProviderUrl = (uri) => {
  try {
    const url = new URL(uri);
    const split = url.host.split(".");

    return split.length > 2 ? split[1] : split[0];
  } catch (err) {
    return "unknown";
  }
}


export const generateRandomString = (length = 4) => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}