import React,{ useState } from "react";
import {
    reportActions,
    reportBottom,
    reportContainer,
    reportIndicator,
    reportIntro,
    reportLabel,
    reportText,
    reportTop,
} from "../pages/Showroom/showroom.style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "./button.component";
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons/faPaperPlane";
import {faAt} from "@fortawesome/free-solid-svg-icons/faAt";
import Input from "./input.component";
import Textarea from "./textarea.component";
import Modal from "./modal.component";
import {contactUs} from "../services/reportUrl";
import Bumper from "./bumper.component";
import {useLocation} from "react-router-dom";

const ContactModal = ({
                          setContactModal
                      }) => {

    const [contactEmail, setContactEmail] = useState(null);
    const [description, setDescription] = useState(null);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const sendReport = (pathname) => {
        setError(false);
        if (contactEmail && description) {
            contactUs(pathname, description, contactEmail).then((res) => {
                setDescription(null);
                setContactEmail(null)
                setTimeout(() => {
                    setSuccess(true);
                    setContactModal()
                }, 5000);
            })
        } else {
           setError(true);
        }
    }

    const { pathname } = useLocation();
    return (
        <>
            { success && ( <Bumper message={'Thank you, your message was successfully sent'} type={'success'} /> ) }
            { error && ( <Bumper message={'Oops, something went wrong, please try again later'} type={'danger'} /> ) }
            <Modal onClose={() => setContactModal()}>
                <div css={reportContainer}>
                    <div css={reportTop}>
                        <div css={reportIntro}><FontAwesomeIcon icon={faPaperPlane} /> Contact us</div>
                        <div css={reportIndicator}>Fill the form below, our team will read this message as soon as possible</div>
                        <div css={reportLabel}><FontAwesomeIcon icon={faAt} /> Your email</div>
                        <div css={reportText}>
                            <Input placeholder={'email@mail.com'} value={contactEmail} onChange={(e) => setContactEmail(e.target.value)}/>
                        </div>
                        <div css={reportLabel}>Message</div>
                        <div css={reportIndicator}>Feel free to give us some details about your report:</div>
                        <div css={reportText}>
                            <Textarea placeholder={"Your message"} value={description} onChange={(e) => setDescription(e.target.value)} style={{minHeight: '155px'}}/>
                        </div>
                    </div>
                    <div css={reportBottom}>
                        <div css={reportActions}>
                            <Button onClick={() => sendReport(pathname)} label={"Send message"}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ContactModal;
