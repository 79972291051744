import React, { useContext, useState, useRef } from "react";
import { css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faRedditAlien,
  faPatreon,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons";
import {
  faLock,
  faTimes,
}  from "@fortawesome/free-solid-svg-icons";

import theme from "../theme";
import Input from "./input.component"
import { style } from "../styles/common";
import Textarea from "./textarea.component";


const container = props => css`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 20px;
  border-radius: ${style(true).mainRadius};
  padding: 16px;
  width: 100%;
  background: rgba(0, 0, 0, .05);
  

  @media (max-width: ${style(true).mobile}) {
    width: 100%;
    background: transparent;
    padding: 0;
  }
`;

const quickUrlContainer = css`
  display: flex;
  margin-top: 10px;
  justify-content: center;
`

const quickIcon = css`
  width: 60px;
  height: 60px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  text-align: center;
  opacity: 0.3;
  transition: opacity 250ms;

  &:hover {
    opacity: 1;
  }
`;

const brandIcon = css`
  color: white;
  font-size: 30px;
`;

const twoCols = css`
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  & > * {
    &:first-child {
      margin-right: 12px;
    }

    &:last-child {
      margin-left: 12px;
    }
  }

  @media (max-width: ${style(true).mobile}) {
    flex-direction: column;

    & > * {
      &:first-child {
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
`;

const removeButton = css`
  top: 10px;
  right: 10px;
  outline: none;
  cursor: pointer;
  position: absolute;
  border: none;
  padding: 5px 8px;
  border-radius: 50%;

  & > * {
    fill: white;
    color: white;
    font-size: 20px;
  }
`


const quickUrls = [
  { color: "#fd1d1d", prefix: "https://instagram.com/", icon: faInstagram, },
  { color: "#1da1f2", prefix: "https://twitter.com/", icon: faTwitter, },
  { color: "#ff4500", prefix: "https://www.reddit.com/user/", icon: faRedditAlien, },
  { color: "#3b5998", prefix: "https://www.facebook.com/", icon: faFacebookF, },
  { color: "#f96854", prefix: "https://www.patreon.com/", icon: faPatreon, },
  { color: "#e60023", prefix: "https://www.pinterest.com/", icon: faPinterestP, },
  { color: "#05b0f3", prefix: "https://onlyfans.com/", icon: faLock, },
  { color: "red", prefix: "", icon: "", },
]


const InputProvider = (props) => {
  const {
    provider,
    onRemove,
    onUpdate,
    onNewEntry,
      index,
  } = props;
  const { mode } = useContext(theme);
  const themedStyle = style(mode === "dark");
  const [prefix, setPrefix] = useState("");
  const [url, setUrl] = useState(provider.url);
  const [name, setName] = useState(provider.name);
  const [description, setDescription] = useState(provider.description);

  const inputEl = useRef(null);

  const createQuickUrlClickHandler = (quickUrl) => () => {
    setUrl("");
    setPrefix(quickUrl.prefix);

    if (onUpdate) {
      onUpdate();
    }

    if (inputEl.current) {
      inputEl.current.focus();
    }
  }

  const onUrlChange = (e) => {
    if (!name && e.target.value) {
      onNewEntry();
    }

    setUrl(e.target.value);
    provider.url = `${prefix}${e.target.value}`;
  }

  const onNameChange = (e) => {
    setName(e.target.value);
    provider.name = e.target.value;
  }

  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
    provider.description = e.target.value;
  }

  const onRemoveHandler = () => {
    setUrl("");
    setName("");
    setPrefix("");
    setDescription("");

    if (onRemove) {
      onRemove();
    }
  }

  return (
    <div css={container({ themedStyle })}>
      {/*  <button css={removeButton} onClick={onRemoveHandler}><FontAwesomeIcon icon={faTimes} /></button>

     <div css={quickUrlContainer}>
        {quickUrls.map((quickUrl) => {
          const iconStyle = { backgroundColor: quickUrl.color }

          if (prefix === quickUrl.prefix) {
            iconStyle.opacity = 1;
          }

          return (
            <div
              role="button"
              css={quickIcon}
              style={iconStyle}
              key={`quickUrl-${quickUrl.color}`}
              onClick={createQuickUrlClickHandler(quickUrl)}
            >
              {
                quickUrl.icon ? <FontAwesomeIcon css={brandIcon} icon={quickUrl.icon} /> : "www"
              }
            </div>
          )
        })}
      </div> */}

       <Input name="url"
        value={url}
        ref={inputEl}
        placeholder="Url"
        title={`Website link #${index +1}`}
        onBlur={onUpdate}
        onChange={onUrlChange}
      />

      <div css={twoCols}>
        <Input
          name="name"
          value={name}
          title={`Link's #${index +1} title`}
          placeholder="Title"
          onBlur={onUpdate}
          onChange={onNameChange}
        />

        <Textarea
          name="description"
          value={description}
          title={`Link #${index +1} description`}
          placeholder="Small description"
          onBlur={onUpdate}
          onChange={onDescriptionChange}
        />
      </div>
    </div>
  );
}


InputProvider.defaultProps = {
  onNewEntry: () => {},
}


export default InputProvider;
