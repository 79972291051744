import React, { Component } from 'react';
import { jsx, css } from '@emotion/react'
import {style} from "../styles/common";

export default class ShareBox extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        const { children, reducedHeader, darkMode } = this.props

        const container = css`
            border-radius: ${style(darkMode).mainRadius};
            background: ${style(darkMode).color.switchableContentColor};
            color: ${style(darkMode).color.switchableTextColor};
            padding: 20px;
            width: 190px;
            display: inline-flex;
            flex-direction: column;
            box-shadow: ${style(darkMode).shadowSoft};

              @media (max-width: ${style(true).mobile}) {
                width: calc(100% - 40px);
              }
            transition: all .6s ease-in-out;
        `;

        const { } = this.props;
        const { } = this.state;

        return (
            <div css={container}>
                { children }
            </div>
        )
    }
}
