import React from "react";
import {comfortPadding, margin, mobileLoader, rows, websiteTip} from "../pages/Showroom/showroom.style";
import AppContainer from "./appContainer.component";
import GeometricalStark from "./geometricalStark.component";
import Rubber from "./rubber.component";
import Loader from "./loader.component";

const ShowroomLoader = ({
                            mode,
                            reducedHeader,
                            swapAvatar,
                            isEven,
                            iteration= 1
                      }) => {
    return (
        new Array(Number(iteration)).fill('1').map((i, index) => (
            <div key={`key-${index}`} css={margin({mode})} >
                <AppContainer darkMode={mode} rubber>
                    <GeometricalStark darkMode={mode}>
                        <Rubber darkMode={mode} content="loading..."/>
                        <div css={comfortPadding({mode})}>

                        </div>
                    </GeometricalStark>
                    <div css={rows({mode, reducedHeader, swapAvatar, isEven})}>
                        <div css={websiteTip({mode})}>
                            <div css={mobileLoader}>
                                <Loader minHeight={100} image/>
                            </div>
                        </div>
                    </div>
                </AppContainer>
            </div>
        ))
    )
}


export default ShowroomLoader;
