import React, { Component } from 'react';
import { jsx, css } from '@emotion/react'
import {style} from "../styles/common";

export default class ContextualMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        const { children, darkMode, avatar, reducedHeader } = this.props

        const container = css`
            border-radius: ${style(darkMode).mainRadius};
            background: ${style(darkMode).color.switchableContentColor};
            color: ${style(darkMode).color.switchableTextColor};
            padding: 20px;
            width: 190px;
            display: inline-flex;
            flex-direction: column;
            font-size: 14px;
            box-shadow: ${style(darkMode).shadowSoft};
           
            transition: all .6s ease-in-out;
        `;

        const { } = this.props;
        const { } = this.state;

        return (
            <div css={container}>
                { children }
            </div>
        )
    }
}
