import Helmet from "react-helmet";


const SEO = ({
  imageUrl,
  title = "",
  type = "website",
  description = "The world first place to find all links from amazing models and websites.",
}) => {
  const computedTitle = `${title}${title ? " - " : ""}Blanquette`;
  const url = window.location.origin + window.location.pathname;

  return (
    <Helmet>
      <html lang="en" amp />
      <title itemProp="name" lang="en">{computedTitle}</title>

      <meta name="description" content={description} />

      <meta property="og:url" content={url} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Blanquette" />
      <meta property="og:title" content={computedTitle} />
      <meta property="og:description" content={description} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}

      <meta name="twitter:site" content={url} />
      <meta name="twitter:card" content="photo" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:creator" content="Blanquette" />
      {imageUrl && <meta name="twitter:image:src" content={imageUrl} />}

    </Helmet>
  );
}


export default SEO;
