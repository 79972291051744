import React, { Component } from 'react';
import { css } from '@emotion/react'
import firebase from 'firebase/app';
import { v4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import Header from "../Header/header.component";
import Button from "../../components/button.component";
import AppContainer from "../../components/appContainer.component";
import Field from "../../components/field.component";
import Input from "../../components/input.component";
import Textarea from "../../components/textarea.component";
import SEO from '../../components/seo.component';
import Tooltip from "../../components/tooltip.component";
import Checkbox from "../../components/checkbox.component";
import InputProvider from "../../components/inputProvider.component";
import { generateRandomString } from '../../helper';
import createLink from '../../services/createLink';
import getLinkById from '../../services/getLinkById';
import blanquetteGif from '../../images/blanquette.gif';
import blanquetteDoneGif from '../../images/blanquetteDone.gif';
import Theme from "../../theme";
import ModalLoader from "../../components/modalLoader.component";
import { WithContext as ReactTags } from 'react-tag-input';
import { searchTag } from "../../services/algolia";
import './tags.style.css';
import {style} from "../../styles/common";
import {faGrinStars} from "@fortawesome/free-regular-svg-icons/faGrinStars";
import Rubber from "../../components/rubber.component";
import GeometricalStark from "../../components/geometricalStark.component";
import HeaderSticky from "../Header/headerSticky.component";
import BarMenu from "../../components/barMenu.component";
import Bumper from "../../components/bumper.component";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const masonryOptions = {
  transitionDuration: 0
}

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const rows = css`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`;

const websiteLink = css`
  display: inline-flex;
  width: 100%;

  @media (max-width: ${style(true).mobile}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
const deleteLink = css`
  background: ${style(true).color.lightRed};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-top: 20px;
  color: #FFFFFF;
  cursor: pointer;

  @media (max-width: ${style(true).mobile}) {
    border-radius: 32px;
    width: 32px;
    height: 32px;
  }
`;

const mainContainer = (mode) => css`
    width: 100%;
    display: inline-flex;
    flex-direction: column;

  @media (max-width: ${style(mode).mobile}) {
    margin-top: 55px;
  }
`;

const cgu = css`
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  
  input {
    margin-right: 10px;
  }
`;

const indicator = (mode) => css`
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 30px;
  color: ${style(mode).color.switchableTextColor};
  font-weight: 300;
  font-size: 18px;
  span {
    line-height: 150%;
  }
`;

const magic = (mode) => css`
  font-weight: 600;
  font-size: 20px;
  color: ${style(mode).color.violet};
`;
const indicatorTip = (mode) => css`
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  color: #FFFFFF;
  padding: 8px;
  border-radius: ${style(mode).mainRadius};
  background: ${style(mode).color.violet};
  
  @media (max-width: ${style(mode).mobile}) {
    font-size: 13px;
    margin-top: 15px;
  }
`;

const indicatorTooltip = (mode) => css`
  font-style: italic;
  font-weight: 300;
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px rgba(255, 255, 255, .2);
`;


const contentContainer = (mode) => css`
  margin-top: 180px;
  width: 100%;
  //margin-left: -20px;

  @media (max-width: ${style(mode).mobile}) {
    margin-top: 50px;
  }
`;

const tagsContainer = () => css`
  width: 100%;
  
  input {
    width: calc(100% - 40px);
  }
`;


export default class Create extends Component {
  /* LIFECYCLE */

  /**
   * @constructor
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {
      id: "",
      tags: [],
      suggestions: [],
      title: "",
      createdLink: null,
      description: "",
      idErrored: false,
      titleErrored: false,
      termChecked: false,
      descriptionErrored: false,
      creationError: false,
      idSuffix: `-${generateRandomString(4)}`,
      providerInfos: [
        this.createDefaultProviderInfo(),
      ],
    }

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  async componentDidMount() {
    const { linkId } = this.props.match.params;

    if (linkId) {
      const link = await getLinkById(linkId);

      if (link) {
        this.setState({
          title: link.title || "",
          description: link.description || "",
          providerInfos: link.providerInfos.map((p) => ({
            url: p.url,
            name: p.name || "",
            description: p.description || "",
          })),
        });
      }
    }
  }

  
  /* METHODS */

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    this.setState(state => ({ tags: [...state.tags, {id: tag.id, text: tag.cleanText ? tag.cleanText : tag.text}] }));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleChange = (e) => {
    const { name, value } = e.target

    this.setState({ [name]: value });
    return value;
  }

  handleTagChange = async(e) => {
    const result = await searchTag(e);

    this.setState({ suggestions: result.map(r => ({ id: r.objectID, text: r.value, used: r.used, cleanText:r.value})) });
  }

  handleUpdateProviderInfos = () => {
    this.setState({ providerInfos: [...this.state.providerInfos] });
  }

  createChangeHandler = (erroredName) => (e) => {
    const value = this.handleChange(e);
    const isErrored = this.state[erroredName];

    if (!value && !isErrored) {
      this.setState({ [erroredName]: true });
    } else if (value && isErrored) {
      this.setState({ [erroredName]: false });
    }
  }

  createProviderChangeHandler = (provider) => () => {
    const { providerInfos } = this.state;
    const index = providerInfos.findIndex(p => p.id === provider.id);

    if (index === (providerInfos.length - 1)) {
      this.setState({ providerInfos: providerInfos.concat(this.createDefaultProviderInfo()) });
    }
  }

  createProviderRemoveHandler = (provider) => () => {
    const { providerInfos } = this.state

    if (providerInfos[0].id !== provider.id) {
      this.setState({ providerInfos: providerInfos.filter(p => p.id !== provider.id )});
    }
  }

  createDefaultProviderInfo = () => {
    return {
      id: v4(),
      url: "",
      name: "",
      description: "",
    }
  }

  createPage = async () => {
    const { providerInfos, description, title, adultOnly } = this.state;
    const db = firebase.firestore();
    await db.collection('links').add({
      description,
      title,
      adultOnly,
      providerInfos,
      createdAt: new Date(),
      updatedAt: new Date(),
    }).then((res) => {
      this.refreshData();
      this.setState({ showBumper: true });
      setTimeout(() => this.setState({ showBumper: false }), 3000);
    }).catch((err) => {
      console.log('err', err)
    })
  }

  handleCreate = async () => {

    this.setState({ sending: 'ongoing', creationError: false});
    const data = {
      id: this.state.id,
      idSuffix: this.state.idSuffix,
      title: this.state.title || "",
      description: this.state.description || "",
      tags: this.state.tags.map(tag => tag.text),
      providerInfos: this.state.providerInfos.filter(p => !!p.url),
    }

    try {
      const response = await createLink(data);
      if( response.data && response.data.id && response.data.link) {
        this.setState({ sending: 'sent',createdLink : `${window.location.origin}/${response.data.id}`});
      }
      console.log("--- link", response.data.id, response.data.link);
    } catch (e) {
      this.setState({creationError: true, sending: false});
      /*setTimeout(() => {
        this.setState({creationError: false});
      }, 6000);*/
    }

    // const a = document.createElement("a");
    // document.body.appendChild(a);
    // a.href = `${window.location.origin}/${response.data.id}`;
    //setTimeout(() => {
      //a.click();
    //}, 1500);
  }

  toggleTerm = () => this.setState({ termChecked: !this.state.termChecked });

  isValid = () => {
    const state = this.state;
    const providerInfosSuccess = state.providerInfos.find(p => p.url.length > 0);

    return state.termChecked && providerInfosSuccess && state.id.length > 2;
  }


  /* RENDER */

  render() {
    const {
      id,
      tags,
      title,
      idSuffix,
      idErrored,
      termChecked,
      description,
      titleErrored,
      descriptionErrored,
      providerInfos,
      sending,
      createdLink,
      suggestions,
      creationError
    } = this.state;

    const { mode } = this.context;

    const removeLink = (index) => {
      const { providerInfos } = this.state;
      const newProviderInfos = [...providerInfos];
      if (providerInfos.length === 1){
        this.setState({providerInfos: [this.createDefaultProviderInfo()]});
      } else {
        this.setState({providerInfos: newProviderInfos.filter((provider, idx) => idx !== index)})
      }
    }

    return (
      <>
        <SEO title="Create a Blanquette" />
        <HeaderSticky />
        <Header />
        <BarMenu />
        {
          creationError && (
              <Bumper message={'Oops, something went wrong during your creation, please try again later'} darkmode={mode} type={'danger'} />
          )
        }
        {
          sending && (
              <ModalLoader link={createdLink} image={ blanquetteGif } imageDone={ sending === 'sent' ? blanquetteDoneGif : null } title={"Creating blanquette"} titleSuccess={sending === 'sent' ? 'Done!' : null} withDots wiggle/>
          )
        }

        <div css={mainContainer(mode)}>
          <div css={indicator(mode)}>
            <span>Quickly create your blanquette in a few steps.</span>
            <span>fill in the information, indicate your social networks / websites,</span>
            <span css={magic(mode)}>let the magic happen. <FontAwesomeIcon icon={faGrinStars} /></span>
          </div>
              <AppContainer darkMode={mode}>
                <GeometricalStark darkMode={mode} text="Indicate general information that will allow users to find you quickly" noAvatar>
                  <Rubber darkMode={mode} content="Step 1/3"/>
                </GeometricalStark>
                <br /><br />
                <div css={contentContainer(mode)}>
                  <Input
                      name="id"
                      value={id}
                      //suffix={idSuffix}
                      isErrored={idErrored}
                      indicator="this will be your custom link"
                      title="Link of your blanquette"
                      placeholder="best-blanquette"
                      onChange={this.createChangeHandler("idErrored")}
                  />
                  {
                    id && (
                        <div css={indicatorTip(mode)}>
                          your link will be "https://www.blanquette.io/{id}{idSuffix}"
                          <div css={indicatorTooltip}>
                            <Tooltip text="We are gonna let you remove this identifiants with PRO formula (coming soon)">
                              Why I am seeing "{idSuffix}" <FontAwesomeIcon icon={faQuestionCircle} />
                            </Tooltip>
                          </div>
                        </div>
                    )
                  }
                  <Input
                      name="title"
                      value={title}
                      isErrored={titleErrored}
                      title="Title of your blanquette"
                      indicator="this title will characterize you, it can be your main nickname used on your networks"
                      placeholder="My fav Blanquette !"
                      onChange={this.createChangeHandler("titleErrored")}
                  />
                  <Input
                      noInput
                      title="Related tags:"
                      indicator="Adding tags allows users who will search to find you. This will also allow you to offer account suggestions"
                  />
                  <div css={tagsContainer}>
                    <ReactTags
                        classNames={{
                          tags: 'tagsClass',
                          tagInput: 'tagInputClass',
                          tagInputField: 'tagInputFieldClass',
                          selected: 'selectedClass',
                          tag: 'tagClass',
                          remove: 'removeClass',
                          suggestions: 'suggestionsClass',
                          activeSuggestion: 'activeSuggestionClass'
                        }}
                        tags={tags}
                        inputFieldPosition="top"
                        handleInputChange={this.handleTagChange}
                        handleDelete={this.handleDelete}
                        suggestions={suggestions.map(r => r && { id: r.id, text: `#${r.text} (${r.used})`, cleanText: r.cleanText})}
                        handleAddition={this.handleAddition}
                        autocomplete
                        handleDrag={this.handleDrag}
                        delimiters={delimiters}
                    />

                    <Textarea
                        name="description"
                        value={description}
                        isErrored={descriptionErrored}
                        indicator="Describe your account, your media, whatever you want!"
                        title="Describe what we will see in your Blanquette"
                        placeholder="A little description of my Blanquette..."
                        onChange={this.createChangeHandler("descriptionErrored")}
                    />
                  </div>
                </div>
              </AppContainer>
              <AppContainer darkMode={mode}>
                <GeometricalStark noAvatar darkMode={mode} text="Indicate all the sites where you appear, we will automatically illustrate your page with your media">
                  <Rubber darkMode={mode} content="Step 2/3"/>
                </GeometricalStark>
                <div css={contentContainer(mode)}>
                  <Input
                      noInput
                      title="Your social networks / websites"
                      indicator="Adding tags allows users who will search to find you. This will also allow you to offer account suggestions"
                  />

                  <div css={rows}>
                    {providerInfos.slice(0,5).map((provider, index) => (
                        <div css={websiteLink}>
                          <InputProvider
                              key={provider.id}
                              provider={provider}
                              index={index}
                              onUpdate={this.handleUpdateProviderInfos}
                              onRemove={this.createProviderRemoveHandler(provider)}
                              onNewEntry={this.createProviderChangeHandler(provider)}
                          />
                          <div css={deleteLink} onClick={() => removeLink(index)}>
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        </div>
                    ))}
                  </div>
                </div>
              </AppContainer>
              <AppContainer darkMode={mode}>
                <GeometricalStark noAvatar darkMode={mode} text="Make sure you have read the General Conditions of Use, and create your blanquette!">
                  <Rubber darkMode={mode} content="Step 3/3"/>
                </GeometricalStark>
                <div css={contentContainer(mode)}>
                  <div css={cgu} onClick={this.toggleTerm}>
                    <Checkbox checked={termChecked}><Field>I have read the term and usage policy.</Field></Checkbox>
                  </div>
                  <div css={rows}>
                    <Button
                        disabled={!this.isValid()}
                        label="Go! Create my blanquette"
                        onClick={this.handleCreate}
                        type="warning"
                    />
                  </div>
                </div>
              </AppContainer>
        </div>
      </>
    )
  }
}

Create.contextType = Theme;