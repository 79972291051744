import React, { Component } from 'react';
import { style, renderColor } from '../styles/common';
import { jsx, css, keyframes, Global } from '@emotion/react';

export default class Button extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { label, icon, type, darkMode, disabled, mini, children, ...rest } = this.props;

        const button = css`
            background: ${disabled ? "swilver" : renderColor(type, darkMode).background};
            color: ${disabled ? "gray" : renderColor(type, darkMode).color};
            min-height: 30px;
            border: none;
            border-radius: ${style(darkMode).mainRadius};
            padding-left: ${mini ? '12px' : '24px'};
            padding-right: ${mini ? '12px' : '24px'};
            padding-top: ${mini ? '4px' : '8px'};
            padding-bottom: ${mini ? '4px' : '8px'};
            display: inline-flex;
            justify-content: center;
            align-items: center;
            letter-spacing: 1px;
            text-transform: capitalize;
            font-size: 16px;
            cursor: ${disabled ? 'not-allowed' : 'pointer'};
            margin-top: 16px;
            text-decoration: none!important;
            margin-bottom: 16px;
            box-shadow: ${style(darkMode).shadow};
            transition: all .1s ease-in-out;
            
            &:hover {
                transform: ${disabled ? 'none' : 'scale(1.01)'};
                background: ${renderColor(type, darkMode)[disabled ? 'inherit' : 'backgroundHover']};
                box-shadow: ${!disabled ? style(darkMode).shadowHover : style(darkMode).shadow};
                transition: all .1s ease-in-out;
            }
             
            &:focus {
                outline: none;
            }
        `;

        return (
            <button css={button} disabled={disabled} {...rest}>
                { label || children }
            </button>
        )
    }
}
