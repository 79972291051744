import React, { useContext } from "react";
import { css } from "@emotion/react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import theme from "../theme";
import Tile from "./tile.component";
import { truncateString } from "../helper";
import * as styles from "../styles/common";


const rangeContainer = props => css`
  width: 100%;
  max-width: 1600px;
  height: 100%;
  position: relative;
  margin: auto;
  margin-bottom: 50px;
  border-radius: ${props.style.mainRadius};

  @media (min-width: ${props.style.mobile}) {
    padding: 20px;
    padding-bottom: 40px;
    background: ${props.style.color.oddGrey};
    box-shadow: ${props.style.shadowSoft};
  }
`

const topContainer = props => css`
  width: 100%;
  height: 100%;
  
  ${props.disableRibbon && 'margin-top: 10px;'};
  @media (max-width: ${props.style.mobile}) {
    //margin-left: -13px;
  }
`

const rangeTitle = props => css`
  color: ${props.style.color.switchableTextColor};
  font-size: 28px;
  padding: 10px;
  padding-top: 0;
  
  svg {
      color: ${props.style.color.violet};
  }
  
  span {
      font-weight: 400;
  }
`

const masonryOptions = {
  transitionDuration: 0
}


const getUrl = (link, provider) => {
  if (link) {
    return `/${link.id}`;
  }

  return provider ? provider.url : "#";
}

const getImageUrl = (link, provider) => {
  if (link) {
    return link.imageUrl;
  }

  return provider ? provider.icon : "";
}

const getProviders = (link, provider) => {
  if (link) {
    return link.providers || [];
  }

  return (provider && [provider]) || [];
}

const getSubtitle = (link, provider) => {
  if (link) {
    return link.title;
  }

  return provider ? provider.username : "";
}


const SectionTiles = ({
  title,
  links = [],
  providers = [],
  disableRibbon,
  name = "sectionTiles",
  maxSubtitleLength = 15,
                        external
}) => {
  const { mode } = useContext(theme);
  const style = styles.style(mode);
  const iterations = links.length + providers.length;
  const arrayMapper = new Array(iterations > 0 ? iterations : 5).fill(0).map((value, index) => index);

  return (
    <div css={rangeContainer({ style })}>
      <div css={rangeTitle({ style })}>{title}</div>
      <div css={topContainer({ style, disableRibbon })}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4 }}>
          <Masonry
            gutter="20px"
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
          >
            {arrayMapper.map((index) => {
              const link = links[index];
              const provider = providers[index];
              const isProvider = !!provider;

              return (
                <Tile
                  external={link && link.hasOwnProperty("previewImageUrl")}
                  provider={provider}
                  key={`${name}-${index}`}
                  href={getUrl(link, provider)}
                  imageUrl={getImageUrl(link, provider)}
                  providers={getProviders(link, provider)}
                  target={isProvider ? "_blank" : undefined}
                  title={!disableRibbon && `Top ${index + 1}`}
                  subtitle={truncateString(getSubtitle(link, provider), maxSubtitleLength)}
                />
              )
            })}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  )
}


export default SectionTiles;
