export const firebaseConfig = {
    apiKey: "AIzaSyAXiz30lpGHgsBjenBamMTiXJWfTudklVE",
    authDomain: "foliox-a18cf.firebaseapp.com",
    projectId: "foliox-a18cf",
    storageBucket: "foliox-a18cf.appspot.com",
    messagingSenderId: "1058518491015",
    appId: "1:1058518491015:web:d1737c4d31943766ac7d22",
    measurementId: "G-0RRTQCYXSQ"
};


export const ALGOLIA_APPID = "OLHTZ946H3";
export const ALGOLIA_SEARCHID = "c7ee89037bffd56c0c1bafc29a282aee";