import Home from './pages/Home/home.component';
import Create from './pages/Create/create.component';
import Showroom from './pages/Showroom/showroom.component';
import Footer from "./pages/Footer/footer.component";
import { style } from './styles/common'
import { css } from '@emotion/react'
import './App.css';
import {BrowserRouter as Router, Route, Switch as Switcher} from "react-router-dom";
import React, {Component} from "react";
import Disclaimer from "./components/disclaimer.component";
import Switch from "react-switch";
import {faMoon} from "@fortawesome/free-solid-svg-icons/faMoon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import ThemeContext from "./theme";


const rootAppContainer = css`
  padding: 20px;

  @media (max-width: ${style(true).mobile}) {
    padding: 12px;
  }
`


export default class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mode: window.localStorage.getItem("mode") || "light",
            showContactModal: false,
            showReportModal: false,
        }
    }

    switchTheme = () => {
        const mode = this.state.mode === "light" ? "dark" : "light";
        this.setState({ mode }, () => window.localStorage.setItem("mode", mode));
    }
    setContactModal = () => {
        this.setState({ showContactModal: !this.state.showContactModal });
    }
    setReportModal = (props) => {
        this.setState({ showReportModal: props });
    }

    render() {
        const { mode, showReportModal, showContactModal } = this.state;

        const rootApp = props => css`
          background: ${style(mode).color.mainBackground};
          ${!window.localStorage.getItem('acceptedBlanquetteCondition') && 'img { filter: blur(10px)!important; }'}
          transition: all .1s ease-in-out;
        `

        return (
            <ThemeContext.Provider value={{ mode, switchTheme: this.switchTheme, showContactModal, showReportModal, setContactModal: this.setContactModal, setReportModal: this.setReportModal }}>
                <Router>
                    <div css={rootApp({ mode })}>
                        <div css={rootAppContainer}>
                            {!window.localStorage.getItem('acceptedBlanquetteCondition') && <Disclaimer />}
                            <Switcher>
                                <Route path="/create/:linkId?" component={Create} />
                                <Route exact path="/">
                                    <Home darkMode={this.state.darkMode} />
                                </Route>
                                <Route exact path="/:id">
                                    <Showroom />
                                </Route>
                            </Switcher>
                        </div>
                    </div>
                    <Footer darkMode={this.state.darkMode} />
                </Router>
            </ThemeContext.Provider>
        );
    }
}
