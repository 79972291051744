import React, { Component } from 'react';
import { jsx, css } from '@emotion/react'
import {style} from "../styles/common";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";

export default class LateralMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                visible: true
            });
        }, 100);
    }

    render() {

        const { children, mode, onClose, title } = this.props

        const container = css`
            background: ${style(mode).color.headerBackground};
            color: ${style(mode).color.switchableTextColor};
            position: fixed;
            top: 0;
            bottom: 0;
            left: ${this.state.visible ? '30%': '100%'};          
            padding: 20px;
            width: 70%;
            display: inline-flex;
            flex-direction: column;
            box-shadow: ${style(mode).shadowSoft};
            border-top-left-radius: ${style(mode).mainRadius};
            border-bottom-left-radius: ${style(mode).mainRadius};
            overflow: auto;
            z-index: 2;
            transition: all .4s ease-in-out;
        `;

        const menuContainer = css`
            font-size: 12px;
            margin-top: 30px;
        `;
        const containerBackground = css`
            background: rgba(0, 0, 0, .4);
            position: fixed;
            left:0;
            right: 0;
            top:0;
            bottom: 0;
            overflow: hidden;
            z-index: 1;
        `;

        const headerMenu = css`
            height: 40px;
            width: calc(100% - 40px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: ${style(mode).color.violet};
        `;

        const leftMenu = css`
           overflow: hidden;
           white-space: nowrap;
           text-overflow: ellipsis;
           font-size: 18px; 
        `;

        const rightMenu = css`
           
        `;

        const { } = this.props;
        const { } = this.state;


        const closeAction = () => {
            setTimeout(() => {
                this.setState({
                    visible: false
                });
            });
            setTimeout(() => {
                onClose()
            }, 400);
        }
        return (
            <>
                <div css={containerBackground} onClick={() => closeAction()}/>
                <div css={container}>
                    <div css={headerMenu}>
                        <div css={leftMenu}>
                            { title }
                        </div>
                        <div css={rightMenu}>
                            <FontAwesomeIcon icon={faArrowRight} onClick={() => closeAction()}/>
                        </div>
                    </div>
                    <div css={menuContainer}>
                        { children }
                    </div>
                </div>
            </>
        )
    }
}
