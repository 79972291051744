import React, { Component } from 'react';
import { jsx, css } from '@emotion/react'
import {style} from "../styles/common";
import {faSync} from "@fortawesome/free-solid-svg-icons/faSync";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import blanquetteGif from '../images/blanquette.gif';
export default class Loader extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        const { minHeight, darkMode, image } = this.props

        const container = css`
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${style(darkMode).color.switchableTextColor};
            font-size: 30px;
            min-height: ${minHeight || 0};
            transition: all .6s ease-in-out;
        `;

        const imageLoader = css`
          width: 100%;
          max-width: 120px;
          opacity: .6;
        `;

        return (
            <div css={container}>
                {!image ? <FontAwesomeIcon icon={faSync} spin /> : <img src={blanquetteGif} css={imageLoader}/> }
            </div>
        )
    }
}
