import React, { useContext } from 'react';
import { style } from '../styles/common'
import { css, keyframes } from '@emotion/react'

import theme from '../theme';
import Theme from "../theme";
import Button from "./button.component";

const dots1 = keyframes` from { opacity: 0; } 25% { opacity: 1; }`;
const dots2 = keyframes` from { opacity: 0; } 50% { opacity: 1; }`;
const dots3 = keyframes` from { opacity: 0; } 70% { opacity: 1; }`;

const wiggleAnimation = keyframes`
    0% {transform: rotate(8deg);}
    25% {transform: rotate(-8deg);}
    50% {transform: rotate(15deg);}
    75% {transform: rotate(-3deg);}
    100% {transform: rotate(8deg);}
  `

const loaderParentContainer = props => css`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 102;
  background: rgba(0 , 0, 0, .85);
`;

const loaderChildrenContainer = props => css`
  width: 400px;
  max-width: 80%;
  min-height: 100px;
  background: #FFFFFF;
  display: flex;
  padding: 10px;
  flex-direction: column;
  border-radius: ${style(props.mode).mainRadius};
  box-shadow: ${style(props.mode).shadow};
`;

const titleStyle = props => css`
  width: 100%;
  font-size: 22px;
  margin-top: 1rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  color: ${style(props.mode).color.violet};
`;

const imageStyle = (wiggle, titleSuccess) => css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  animation: ${(wiggle && !titleSuccess) ? wiggleAnimation: 'none'} 1.7s infinite;
  
  img {
    max-width: 200px;
    width: 100%;
  }
`;
const goLink = () => css`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const dots = props => css`
  display: flex;
  & > span {
    animation: ${dots1} 2s infinite steps(1);
    &:first-child + span {
      animation-name: ${dots2};
    }
    &:first-child + span + span {
      animation-name: ${dots3};
    }
  }
`;



const ModalLoader = (props) => {
    const {
        title,
        image,
        imageDone,
        withDots,
        wiggle,
        titleSuccess,
        link
    } = props;
    const { mode } = useContext(theme);

    return (
        <div css={loaderParentContainer}>
            <div css={loaderChildrenContainer}>
                <div css={titleStyle(mode)}>
                    { titleSuccess || title }{ withDots && (
                    <span css={dots}><span>.</span><span>.</span><span>.</span></span>
                    )}
                </div>
                { image && (
                    <div css={imageStyle(wiggle, titleSuccess)}>
                        <img src={imageDone || image} />
                    </div>
                )}
                { link && (
                    <div css={goLink}>
                        <a href={link}>
                            <Button label="Taste your blanquette" />
                        </a>
                    </div>
                )}
            </div>
        </div>
    )
}
ModalLoader.contextType = Theme;
export default ModalLoader;
