import firebase from "firebase/app";


const getLinkById = async (linkId) => {
  if (!linkId) {
    console.error("[getLinkById] 'linkId' is undefined");
    return;
  }

  const db = firebase.firestore();
  const snapshot = await db.collection("links").doc(linkId).get();

  return snapshot.exists ? snapshot.data() : null;
}

export default getLinkById;
