import React, {useState} from "react";
import {
    radioStyle, reportActions, reportBottom,
    reportContainer,
    reportIndicator,
    reportIntro,
    reportLabel,
    reportOptions,
    reportText,
    reportTop,
} from "../pages/Showroom/showroom.style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "./button.component";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import reportUrl, {contactUs, REPORTURL_REASON} from "../services/reportUrl";
import Textarea from "./textarea.component";
import Modal from "./modal.component";
import {useParams} from "react-router-dom";
import Bumper from "./bumper.component";

const ReportModal = ({
                         setReportModal,
                         selectedProvider,
                         showReportModal,
                      }) => {

    const [reason, setReason] = useState(null);
    const [description, setDescription] = useState(null);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const SendReport = (id) => {
        setError(false);
        
        if (description && showReportModal.providerId && reason && showReportModal.providerUrl) {
            reportUrl(showReportModal.providerId, showReportModal.provider, description, {
                reportUrlReason: reason,
            }).then((res) => {
                setError(false);
                setSuccess(true);
                setDescription(null);
                setReason(null);
                setTimeout(() => {
                    setSuccess(false);
                    setReportModal(false)
                }, 5000);
            })
        } else {
            setError(true);
        }
        
    }

    const { id } = useParams();
    return (
        <>
            { success && ( <Bumper message={'Thank you, your request was successfully sent'} type={'success'} /> )}
            { error && ( <Bumper message={'Oops, something went wrong, please try again later'} type={'danger'} /> )}
            <Modal onClose={() => setReportModal(false)}>
                <div css={reportContainer}>
                    <div css={reportTop}>
                        <div css={reportIntro}><FontAwesomeIcon icon={faInfoCircle} /> Please explain us why <span>{selectedProvider}</span> should be reported:</div>
                        <div css={reportLabel}>Reason</div>
                        <div css={reportOptions}>
                            {Object.entries(REPORTURL_REASON).map((reasonValue, index) => (
                                (reasonValue[0] !== 'FEEDBACK') && <div css={radioStyle} ey={`reason-${index}`} onClick={(e) => setReason(reasonValue[0])}>
                                    <input type="radio" name={`reason-${index}`} value={reasonValue[0]} onChange={() => null } checked={reason === reasonValue[0]}/> {reasonValue[1]}
                                </div>
                            ))}
                        </div>
                        <div css={reportLabel}>Description</div>
                        <div css={reportIndicator}>Feel free to give us some details about your report:</div>
                        <div css={reportText}>
                            <Textarea placeholder={"Your message"} onChange={(e) => setDescription(e.target.value)} style={{minHeight: '155px'}}/>
                        </div>
                    </div>
                    <div css={reportBottom}>
                        <div css={reportActions}>
                            <Button onClick={() => SendReport(id)} label={"Send report"}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>

    )
}

export default ReportModal;
