import React, { useState, useEffect } from "react";
import { css, keyframes } from '@emotion/react';
import * as styles from '../styles/common'
import Rubber from "./rubber.component";
import { selectRandomImage, loadImage } from "../image";
import ImageLoader from "./imageLoader.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {style} from "../styles/common";

const container = (props) => css`
  width: 100%;
  max-width: 100%;
  height: auto;
  text-decoration: none;
  min-height: 300px;
    ${props.related && 'min-width: 200px;'};
  
    &:hover {
      transform: scale(1.02) rotate(-0.4deg);
      transition: all .3s ease-in-out;
  }

  transition: all .3s ease-in-out;
`

const loadAnimation = keyframes`
  from {
    left: -150px;
  }
  to   {
    left: 100%;
  }
`

const loadingImage = props => css`
  width: 100%;
  height: 100%;
  min-height: 280px;
  background: ${props.style.color.violet};
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #FFFFFF 50%, transparent 100%);
    animation: ${loadAnimation} 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
  transition: all .1s ease-in-out;
`

const BackgroundImage = props => css`
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props.style.mainRadius};
  background-position: center;
  background-size: cover;
  box-shadow: ${props.style.shadow};
  
  &:hover {
    box-shadow: ${props.style.shadowHover};
    transition: all .1s ease-in-out;
  }
  transition: all .1s ease-in-out;
`

const corner = props => css`
  background: rgba(150, 120, 207, .95);
  height: 30px;
  width: 100%;
  border-bottom-left-radius: ${props.style.mainRadius};
  border-bottom-right-radius: ${props.style.mainRadius};
  display:flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-weight: bold;
  margin-top: -30px;
  font-size: 16px;
  svg {
    margin-left: 8px;
  }
  
  @media (max-width: ${props.style.mobile}) {
    font-size: 14px;
  }
`
const externalContainerTile = props => css`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  
`
const externalIconTile = props => css`
  position: absolute;
  margin-top: 0;
  margin-right: 0;
  background: ${style(true).color.violet};
  border-top-right-radius: ${style(true).mainRadius};
  border-bottom-left-radius: ${style(true).mainRadius};
  padding: 8px;
  font-size: 12px;
  color: #FFFFFF;
  opacity: .8;
  z-index: 20;
  
`

const Tile = ({
  href,
  title,
  target,
  imageUrl,
  providers,
  subtitle,
  darkMode,
  related,
  loading,
  external,
}) => {
  let imageIndex = 0;

  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [listImageUrls, setListImageUrls] = useState([]);
  const [computedImageUrl, setComputedImageUrl] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const style = styles.style(darkMode);

  useEffect(() => {
    const nextDefaultImageUrl = imageUrl || selectRandomImage(providers);

    setComputedImageUrl(nextDefaultImageUrl);
    setDefaultImageUrl(nextDefaultImageUrl);
    setListImageUrls(Array.isArray(providers) ? new Array(5).fill(0).map(() => selectRandomImage(providers)) : []);
  }, [imageUrl, providers])

  const imageErrored = (imageUrl || (Array.isArray(providers) && providers.length > 0)) && !computedImageUrl;

  const slidePictures = () => {
    setTimeoutId(setTimeout(async () => {
      let nextImageUrl = await loadImage(listImageUrls[imageIndex]);
      imageIndex = nextImageUrl ? (imageIndex + 1) % listImageUrls.length : 0;

      setComputedImageUrl(nextImageUrl || defaultImageUrl);
      slidePictures();
    }, 1000));
  }

  const onSlidePictures = () => {
    imageIndex = 0;
    clearTimeout(timeoutId);
    slidePictures();
  }

  const resetPicture = () => {
    clearTimeout(timeoutId);
    setComputedImageUrl(defaultImageUrl);
  }

  return (
    <a
      href={href}
      target={target}
      rel="noreferrer"
      css={container({ related })}
      onMouseEnter={onSlidePictures}
      onMouseLeave={resetPicture}
    >
      { title && <Rubber content={loading ? 'loading...' : title} staticRubber />}
      { external && <div css={externalContainerTile}>
        <div css={externalIconTile}>
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </div>
      </div>}
      <ImageLoader
        external={external}
        name={subtitle}
        src={computedImageUrl}
        errored={imageErrored}
        onLoading={() => <div css={loadingImage({ style })} />}
        onLoaded={(src) => <div css={BackgroundImage({ style })} style={{ backgroundImage: `url(${src})` }} />}
        onErrored={(label) => <div css={BackgroundImage({ style })}>{label}</div>}
      />

      {
        !loading && <div css={corner({ style })}>
          {subtitle}
        </div>
      }
    </a>
  )
}


export default Tile;
