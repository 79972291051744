import React, { useEffect, useState } from "react";
import { css } from "@emotion/react"
import ColorHash from "color-hash";
import * as styles from "../styles/common";


export const ImageLoaderState = {
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERRORED: "ERRORED",
}

const style = styles.style();
const colorHash = new ColorHash();


export const AvatarContainer = css`
  padding-top: 56.25%;
  position: relative;
`

export const AvatarContent = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AvatarText = css`
  font-weight: bold;
  font-size: 70px;
`



const ImageLoader = ({
  src,
  name,
  render,
  images,
  errored,
  onLoaded,
  onLoading,
  onErrored,
  external
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const [color, setColor] = useState(style.color.primary);
  const [loaderState, setLoaderState] = useState(ImageLoaderState.LOADING);
  const label = <label css={AvatarText} style={{ color }}>{name.substring(0, 1).toUpperCase()}</label>

  useEffect(() => {
    if (imageUrl === src) {
      return;
    }

    if (!src) {
      setLoaderState(ImageLoaderState.LOADING);
      return;
    }

    const image = new Image();

    image.onload = () => setLoaderState(ImageLoaderState.LOADED);
    image.onerror = () => setLoaderState(ImageLoaderState.ERRORED);

    image.src = src;
    setImageUrl(src);
    setLoaderState(ImageLoaderState.LOADING);
  }, [src, imageUrl]);

  useEffect(() => {
    setColor(colorHash.hex(name));
  }, [name]);


  if (render) {
    return render(imageUrl, loaderState);
  }

  if (onErrored && (loaderState === ImageLoaderState.ERRORED || errored)) {
    return onErrored(label);
  }

  if (onLoading && loaderState === ImageLoaderState.LOADING) {
    return onLoading();
  }

  if (onLoaded && loaderState === ImageLoaderState.LOADED) {
    return onLoaded(src);
  }


  return (
    <div css={AvatarContainer}>

      <div css={AvatarContent}>
        {label}
      </div>
    </div>
  )
}


ImageLoader.defaultProps = {
  errored: false,
  name: "Blanquette",
}


export default ImageLoader;
