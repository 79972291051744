import React from "react";
import {relatedStyle, paddingShadow} from "../pages/Showroom/showroom.style";
import AppContainer from "./appContainer.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleDown} from "@fortawesome/free-regular-svg-icons/faArrowAltCircleDown";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Tile from "./tile.component";
import {truncateString} from "../helper";

const RelatedProfiles = ({
    mode,
    masonryOptions,
    related,
    currentLink
}) => {

    const tmpRelated = [];
    const uniqueRelated = [];
    if (related) {
        related.forEach((r) => {
            if (!tmpRelated.includes(r.title) && r.title !== currentLink.title){
                tmpRelated.push(r.title);
                uniqueRelated.push(r);
            }
        })
    }

    return (
        (uniqueRelated && uniqueRelated.length > 0) ?
        <>
            <div css={relatedStyle(mode)} ><FontAwesomeIcon icon={faArrowAltCircleDown} />You would surely like these blanquettes<FontAwesomeIcon icon={faArrowAltCircleDown} /></div>
            <AppContainer darkMode={mode} transparent enlarge>
                <div css={paddingShadow}>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 2, 750: 3, 900: 3, 1280: 4}}
                >
                    <Masonry
                        options={masonryOptions} // default {}
                        disableImagesLoaded={false} // default false
                        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                        gutter={'20px'}
                    >
                        { uniqueRelated.slice(0, 10).map((rel, index) => (
                            <Tile
                                mode
                                key={`/${rel.objectID}`}
                                imageUrl={rel.imageUrl}
                                href={`/${rel.objectID}`}
                                subtitle={rel.title ? truncateString(rel.title, 100) : ""}
                                related
                            />
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
                </div>
            </AppContainer>
        </> : null

    )
}


export default RelatedProfiles;
