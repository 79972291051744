import React, { Component } from 'react';
import { style } from '../styles/common'
import { jsx, css, keyframes, Global } from '@emotion/react'

export default class AppContainer extends Component {

    render() {
        const { children, rubber, darkMode, transparent, isReport, full, enlarge } = this.props;

        const container = css`
            height: 100%;
            border-radius:${style(darkMode).mainRadius};
            ${isReport && 'border-top-right-radius: 0;'};
            ${full && 'width: 100%;'};
            ${enlarge && 'width: calc(100% + 20px);'};
            ${enlarge && 'padding-left: 20px;'};
            ${enlarge && 'padding-right: 20px;'};
            background: ${transparent ? 'transparent' : style(darkMode).color.switchableContentColor};
            padding: ${transparent ? '0px' :'20px'};
            display: flex;
            ${transparent && 'padding-bottom: 50px;'};
            flex-direction: column;
            overflow: hidden;
            box-shadow: ${transparent ? 'none': style(darkMode).shadowSoft};
              @media (max-width: ${style(darkMode).mobile}) {
                padding: 12px;
              }
        `;

        const mainContainer = css`
            height: 100%;
            max-width: 1200px;
            width: 100%;
            margin: auto;
            overflow: hidden;
            margin-bottom: 30px;
            border-radius: ${style(darkMode).mainRadius};
          ${isReport && 'border-top-right-radius: 0;'};
            box-shadow: ${transparent ? 'none' : style(darkMode).shadow};
        `;

        const miniRubberTop = css`       
            position: absolute;
            height: 4px;
            background: ${style(darkMode).color.violet};
            width: 96px;
            margin-left: 70px;
            border-top-left-radius: 30px;
            border-top-right-radius: 10px;
            box-shadow: rgb(0 0 0 / 25%) 4px -8px 10px -1px;
            z-index: 22;
            margin-top: -3px;
            
            @media (max-width: ${style(darkMode).mobile}) {
                display: none;
            }
            
        `;
        const miniRubberLeft = css`       
            position: absolute;
            height: 4px;
            background: ${style(darkMode).color.violet};
            width: 45px;
            border-top-left-radius: ${style(darkMode).mainRadius};
            border-top-right-radius: ${style(darkMode).mainRadius};
            box-shadow: rgb(0 0 0 / 25%) 5px -2px 10px;
            transform: rotate(-90deg);
            margin-left: -24px;
            margin-top: 52px;

            @media (max-width: ${style(darkMode).mobile}) {
              margin-left: -17px;
              margin-top: 48px;
              height: 4px;
              width: 30px;
              font-weight: 300;
            }
        `;

        return (
            <div css={mainContainer}>
                {
                    rubber && (
                        <>
                            <div css={miniRubberTop} />
                            <div css={miniRubberLeft} />
                        </>
                    )
                }
                <div css={container}>
                    { children }
                </div>
            </div>
        )
    }
}
