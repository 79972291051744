import React, { Component } from 'react';
import { style } from '../styles/common'
import { jsx, css, keyframes, Global } from '@emotion/react'
import {gifsArr} from "../pages/Showroom/showroom.component";


export default class Video extends Component {

    render() {
        const { url, embedVideoCss, darkMode } = this.props;

        return (
            <video css={embedVideoCss} loop="loop" preload="auto" muted="muted" autoPlay="autoPlay" controls>
                <source src={url} type="video/mp4" />
            </video>
        )
    }
}
