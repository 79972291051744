import { css } from '@emotion/react';
import rubberSource from '../images/rubber.png';
import React from "react";
import {style} from "../styles/common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight} from "@fortawesome/free-solid-svg-icons/faArrowAltCircleRight";
import GeometricalStark from "./geometricalStark.component";

const CornerLink = ({
        url,
        onClickAction,
        fill,
        darkMode
    }) => {


    const viewMore = css`       
       text-align: right;
       width: calc(100% - 200px);
       height: 100%;
       text-decoration: none;
       color: ${style(darkMode).color.darkGrey};
       font-weight: 600;
       cursor: pointer;
       
       @media (max-width: ${style(darkMode).mobile}) {
            width: calc(100% - 30px);
            text-align: right;
            margin-top: -8px;
            font-size: 14px;
       }
            
       transition: all .3s ease-in-out;
    `;

    return (
        <a rel="noreferrer" target="_blank" href={url} onClick={onClickAction}>
            <GeometricalStark reverse type="fill" fill>
                <div css={viewMore}>
                    View more <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </div>
            </GeometricalStark>
        </a>
    )
}


export default CornerLink;
