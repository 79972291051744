import React, { Component } from 'react';
import { Link } from "react-router-dom";
import firebase from 'firebase/app';
import SEO from '../../components/seo.component';
import { css } from '@emotion/react';
import Header from "../Header/header.component";
import Button from "../../components/button.component";
import Theme from "../../theme";
import SectionTiles from "../../components/sectionTiles.component";
import HeaderSticky from "../Header/headerSticky.component";
import * as styles from "../../styles/common"
import { getLastUpdatedLinks, getRecentLinks, getTopLinks, getTopProviders, getTopProvidersBySource } from '../../services/getTops';



const homeContainer = props => css`
    max-width: 1200px;
    margin: auto;
    margin-top: 40px;

    @media (max-width: ${props.style.mobile}) {
    margin-top: 60px;
    }
`
const introduction = props =>  css`
    color: ${props.style.color.switchableTextColor};
    font-style: italic;
    font-size:16px;
    font-weight: 300;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
`

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            topLinks: [],
            recentLinks: [],
            topProviders: [],
            updatedLinks: [],
            topRedditProviders: [],
            topOnlyFanProviders: [],
            topTwitterProviders: [],
            topInstagramProviders: [],
        }
    }

    async componentDidMount() {
        const db = firebase.firestore();
        const dateObject = this.getDateObject();

        getTopLinks(db, dateObject).then((topLinks) => this.setState({ topLinks }));
        getRecentLinks(db).then((recentLinks) => this.setState({ recentLinks }));
        getTopProviders(db, dateObject).then((topProviders) => this.setState({ topProviders }))
        getTopProvidersBySource(db, dateObject, "instagram").then((topInstagramProviders) => this.setState({ topInstagramProviders }))
        getTopProvidersBySource(db, dateObject, "twitter").then((topTwitterProviders) => this.setState({ topTwitterProviders }))
        getTopProvidersBySource(db, dateObject, "onlyfans").then((topOnlyFanProviders) => this.setState({ topOnlyFanProviders }))
        getTopProvidersBySource(db, dateObject, "reddit").then((topRedditProviders) => this.setState({ topRedditProviders }))
        getLastUpdatedLinks(db).then((updatedLinks) => this.setState({ updatedLinks }))
    }


    /**
     * Get the date in object format
     */
    getDateObject() {
        const date = new Date();
        const month = date.getMonth();
        const year = date.getFullYear();
        const id = `${month}-${year}`;

        return {
            id,
            year,
            month,
        }
    }

    render() {
        const {
            recentLinks,
            topLinks,
            topProviders,
            updatedLinks,
            topRedditProviders,
            topTwitterProviders,
            topOnlyFanProviders,
            topInstagramProviders,
        } = this.state;

        const sources = [
            { source: "instagram", providers: topInstagramProviders }, 
            { source: "reddit", providers: topRedditProviders },
            { source: "twitter", providers: topTwitterProviders },
            { source: "onlyfans", providers: topOnlyFanProviders },
        ];

        const { mode } = this.context;
        const style = styles.style(mode);

        return (
            <>
                <SEO
                    type="article"
                    title={"Blanquette"}
                    description={"The world first place to find all links from amazing models and websites"}
                />
                <Header />
                <HeaderSticky />

                <div css={homeContainer({ style })}>
                    <div css={introduction({ style })}>
                        <div>Blanquette allows you to directly integrate all your links with content preview.</div>
                        <Link to="/create">
                            <Button mode={mode === "dark"} label="Create my link" type="default" />
                        </Link>

                    </div>

                    <SectionTiles
                        name="topLinks"
                        links={topLinks}
                        title={<>Top <span>{topLinks.length > 0 ? topLinks.length : ""}</span> Blanquettes of the month</>}
                    />

                    <SectionTiles
                        disableRibbon
                        name="updatedLinks"
                        title="Last updated blanquettes"
                        links={updatedLinks}
                    />

                    <SectionTiles
                        disableRibbon
                        name="recentLinks"
                        title="Recent blanquettes"
                        links={recentLinks}
                    />

                    <SectionTiles
                        external
                        name="topProviders"
                        providers={topProviders}
                        title={<>Top <span>{topProviders.length > 0 ? topProviders.length : ""}</span> links clicked of the month</>}
                    />

                    {sources.map((source) => source.providers.length > 0 && (
                        <SectionTiles
                            external
                            providers={source.providers}
                            key={`source-${source.source}`}
                            name={`source-${source.source}`}
                            title={<>Top <span>{source.providers.length > 0 ? source.providers.length : ""}</span> {source.source} of the month</>}
                        /> 
                    ))}
                </div>
            </>
        )
    }
}
Home.contextType = Theme;