import Theme from "../theme";

export const style = (mode) => {
    return({
        shadow: "rgb(0 0 0 / 28%) 0px -4px 16px 0px",
        shadowBottom: "rgb(0 0 0 / 28%) 1px 13px 16px -17px",
        shadowTop: "rgb(0 0 0 / 28%) 0px 4px 16px 0px",
        shadowHover: "rgb(0 0 0 / 38%) 0px 4px 16px 0px",
        shadowSoft: "rgb(0 0 0 / 10%) 0px 4px 16px 0px",
        shadowMiddle: "rgb(0 0 0 / 18%) -1px -5px 11px 10px",
        mainRadius: "5px",
        mobile: "720px",
        color: {
            primary: "rgba(150, 120, 207, .95)",
            mainBackground: mode === "dark" ? '#272727' :'#eef0f1',
            inputBackground: mode === "dark" ? '#272727' :'#FFFFFF',
            inputBorder: mode === "dark" ? '#272727' :'#FFFFFF',
            evenGrey: mode === "dark" ? '#272727' :'#FFFFFF',
            oddGrey: mode === "dark" ? '#1f1f1f' :'#FFFFFF',
            headerBackground: mode === "dark" ? '#191818' :'#FFFFFF',
            switchableTextColor: mode === "dark" ? '#FFFFFF' :'#191818',
            switchableContentColor: mode === "dark" ? '#353535' :'#FFFFFF',
            violet: '#9778d0',
            lightViolet: '#c2abec',
            lightGrey: "#eef0f1",
            extraLightGrey: "#f7f7f8",
            lightGreen: "#44b96f",
            lightGreenHover: "#32a25b",
            lightBlue: "#54aaf7",
            lightBlueHover: "#1b6eb9",
            lightOrange: "#ff8263",
            lightOrangeHover: "#f96f4c",
            lightRed: "#e40253",
            lightRedHover: "#b10241",
            stroke: mode === "dark" ? '#272727' :'#dedede',
            darkGrey: "#181818",
        }
    })
}

export const renderColor = (type, mode) => {
    switch (type) {
        case "info":
            return { color: "#FFFFFF", background: style(mode === "dark").color.lightBlue, backgroundHover: style(mode === "dark").color.lightBlueHover};
        case "success":
            return { color: "#FFFFFF", background: style(mode === "dark").color.lightGreen, backgroundHover: style(mode === "dark").color.lightGreenHover};
        case "warning":
            return { color: "#FFFFFF", background: style(mode === "dark").color.lightOrange, backgroundHover: style(mode === "dark").color.lightOrangeHover};
        case "danger":
            return { color: "#FFFFFF", background: style(mode === "dark").color.lightRed, backgroundHover: style(mode === "dark").color.lightRedHover};
        case "link":
            return { color: "#000000", background: "transparent", backgroundHover: style(mode === "dark").color.lightRedHover, boxShadow: style(mode === "dark").shadow}; //rgb(0 0 0 / 15%) 0px -3px 10px;
        case "fill":
            return { color: "#FFFFFF", background: "#FFFFFF", backgroundHover: style(mode === "dark").color.lightRedHover, boxShadow: style(mode === "dark").shadow}; //rgb(0 0 0 / 15%) 0px -3px 10px;
        case "default":
            return { color: "#FFFFFF", background: style(mode === "dark").color.violet, backgroundHover: style(mode === "dark").color.lightViolet, boxShadow: style(mode === "dark").shadow}; //rgb(0 0 0 / 15%) 0px -3px 10px;
        default:
            return { color: "#FFFFFF", background: style(mode === "dark").color.violet, backgroundHover: style(mode === "dark").color.lightViolet, boxShadow: style(mode === "dark").shadow};
    }
}
