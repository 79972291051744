
export const getTopLinks = async (db, dateObject) => {
  const topLinkSnapshots = await db.collection("stats").doc(dateObject.id).collection("links").orderBy("viewed", "desc").limit(10).get();
  const topLinks = topLinkSnapshots.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const links = await Promise.all(topLinks.map(async (topLink) => {
    const snapshot = await db.collection("links").doc(topLink.linkId).get();
    const providerSnaphosts = await db.collection("links").doc(topLink.linkId).collection("providers").get();

    return {
      id: snapshot.id,
      ...snapshot.data(),
      providers: providerSnaphosts.docs.map((doc) => doc.data()),
    }
  }));

  return topLinks.map((topLink) => {
    const link = links.find(l => l.id === topLink.linkId);

    return {
      ...topLink,
      ...link,
    }
  });
}


const populateTopProviders = async (db, topProviders) => {
  const providers = await Promise.all(topProviders.map(async (topProvider) => {
    const snapshot = await db.collection("providers").doc(topProvider.providerId).get();

    return {
      id: snapshot.id,
      ...snapshot.data(),
    }
  }));

  return topProviders.map((topProvider) => {
    const provider = providers.find(p => p.id === topProvider.providerId);

    return {
      ...topProvider,
      ...provider,
    }
  });
}

export const getTopProviders = async (db, dateObject) => {
  const topProviderSnapshots = await db.collection("stats").doc(dateObject.id).collection("providers").orderBy("clicked", "desc").limit(10).get();

  const topProviders = topProviderSnapshots.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return populateTopProviders(db, topProviders);
}

export const getTopProvidersBySource = async (db, dateObject, source) => {
  const topProviderSnapshots = await db.collection("stats").doc(dateObject.id).collection("providers")
    .where("source", "==", source)
    .orderBy("clicked", "desc")
    .limit(10)
    .get();

  const topProviders = topProviderSnapshots.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return populateTopProviders(db, topProviders);
}

export const getRecentLinks = async (db) => {
  const recentLinkSnapshots = await db.collection("links").orderBy("createdAt", "desc").limit(10).get();

  const recentLinks = recentLinkSnapshots.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return Promise.all(recentLinks.map(async (recentLink) => {
    const providerSnapshots = await db.collection("links").doc(recentLink.id).collection("providers").get();

    return {
      ...recentLink,
      providers: providerSnapshots.docs.map((doc) => doc.data()),
    }
  }))
}

export const getLastUpdatedLinks = async (db) => {
  const updatedLinkSnapshots = await db.collection("links").orderBy("updatedAt", "desc").limit(10).get();


  const updatedLinks = updatedLinkSnapshots.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return Promise.all(updatedLinks.map(async (updatedLink) => {
    const providerSnapshots = await db.collection("links").doc(updatedLink.id).collection("providers").get();

    return {
      ...updatedLink,
      providers: providerSnapshots.docs.map((doc) => doc.data()),
    }
  }));
}
