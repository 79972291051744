import React, { Component } from 'react';
import { style } from '../styles/common'
import { jsx, css, keyframes, Global } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";

export default class Bumper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        }
    }

    componentDidMount() {
        const { timeout } = this.props;
        setTimeout(() => {
            this.setState({ visible: true})
        }, 500);

        setTimeout(() => {
            this.setState({ visible: false})
        }, timeout + 500);
    }

    render() {
        const { message, darkMode, type } = this.props;
        const { providers, counter } = this.state;

        const bumper = css`
            background: ${(type && type === 'danger') ? style(darkMode).color.lightRed : style(darkMode).color.violet};
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 60px;
            color: #FFFFFF;
            top: ${this.state.visible ? 0 : '-60px'};
            width: 100%;    
            left: 0;
            position: fixed;
            z-index: 9999;
            border-bottom-left-radius: ${style(darkMode).mainRadius};
            border-bottom-right-radius: ${style(darkMode).mainRadius};
            font-weight: bold;
            box-shadow: ${this.state.visible ? style(darkMode).shadow : 'none'};
          
          svg {
            margin-right: 8px;
          }
            transition: all .3s ease-in-out;  
        `;

        return (
            <div css={bumper}>
                {(type && type === 'danger') && <FontAwesomeIcon icon={faExclamationTriangle} />} { message }
            </div>
        )
    }
}
