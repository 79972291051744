import React,{ useState } from "react";
import {
    comfortPadding,
    imageRow,
    imgContainer,
    link,
    margin,
    marginEmpty,
    mobileDisplay,
    reportStyle,
    reportStyleContent,
    row,
    rows,
    showdesktop,
    socialData,
    socialItem,
    video,
    websiteTip,
    websiteTipContent,
    editDate,
    userDetailsContainer
} from "../pages/Showroom/showroom.style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AppContainer from "./appContainer.component";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import GeometricalStark from "./geometricalStark.component";
import Rubber from "./rubber.component";
import {parseProviderUrl} from "../helper";
import Title from "./title.component";
import Description from "./description.component";
import {faPhotoVideo} from "@fortawesome/free-solid-svg-icons/faPhotoVideo";
import {faImages} from "@fortawesome/free-regular-svg-icons/faImages";
import {faVideo} from "@fortawesome/free-solid-svg-icons/faVideo";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import MediaViewer from "./mediaViewer.component";
import CornerLink from "./cornerLink.component";
import {Element} from "react-scroll";
import Moment from 'react-moment';

const masonryOptions = {
    transitionDuration: 0,
    percentPosition: true,
    columnWidth: 50
}

const ProviderData = ({
                          provider,
                          mode,
                          reducedHeader,
                          swapAvatar,
                          isEven,
                          linkId,
                          parentIndex,
                          setReportModal,
                          createClickProviderHandler
                      }) => {

    return (
        <Element name={`${provider.source}-${parentIndex}`}
                 css={margin({mode, reducedHeader, swapAvatar, isEven})}
                 key={`website-elem-${parentIndex}`}>
            <AppContainer darkMode={mode} key={`website-${parentIndex}`} rubber isReport>
                <div css={reportStyle}>
                    <div css={reportStyleContent} onClick={() => setReportModal({
                        providerId: provider.id,
                        providerUrl: provider.url,
                        selectedProvider: provider.source,
                        provider
                    })}>
                        <FontAwesomeIcon icon={faExclamationTriangle}/> report website
                    </div>
                </div>
                <GeometricalStark
                    isReport
                    rootData={provider.media && provider.media['previewImageUrl' || 'url']}
                    darkMode={mode}
                    providerData={provider}
                >
                    <Rubber darkMode={mode}
                            content={provider && (provider.source === "unknown" ? parseProviderUrl(provider.url) : provider.source)}/>
                    <div css={comfortPadding({mode, reducedHeader, swapAvatar, isEven})}>
                        <div css={showdesktop({mode, reducedHeader, swapAvatar, isEven})}>
                            {
                                provider.username && (
                                    <Title darkMode={mode}
                                           max={window.innerWidth < 421 ? 50 : 80} white
                                           content={`${provider.username !== 'unknown' ? `${provider.username}` : ``}`}/>
                                )
                            }
                            <Description darkMode={mode} white max={80}
                                         content={provider.description}/>
                        </div>
                    </div>
                    <div css={link({mode, reducedHeader, swapAvatar, isEven})}>
                        <a href={provider.url} target='_blank'>
                            {provider.url}
                        </a>
                    </div>
                </GeometricalStark>
                <div css={rows({mode, reducedHeader, swapAvatar, isEven})}>
                    {
                        (((provider.data && provider.data.posts) || (provider.data && provider.data.followers)) && (provider.data && provider.data.posts !== 'O') && (provider.data && provider.data.followers !== '0')) && (
                            <div
                                css={socialData({mode, reducedHeader, swapAvatar, isEven})}>
                                {provider.data && provider.data.posts && <div
                                    css={socialItem({
                                        mode,
                                        reducedHeader,
                                        swapAvatar,
                                        isEven
                                    })}><FontAwesomeIcon icon={faPhotoVideo}/>
                                    <span>{provider.data && provider.data.posts}</span>
                                </div>}
                                {provider.data && provider.data.photos && <div
                                    css={socialItem({
                                        mode,
                                        reducedHeader,
                                        swapAvatar,
                                        isEven
                                    })}><FontAwesomeIcon icon={faImages}/>
                                    <span>{provider.data && provider.data.photos}</span>
                                </div>}
                                {provider.data && provider.data.videos && <div
                                    css={socialItem({
                                        mode,
                                        reducedHeader,
                                        swapAvatar,
                                        isEven
                                    })}><FontAwesomeIcon icon={faVideo}/>
                                    <span>{provider.data && provider.data.videos}</span>
                                </div>}
                                {provider.data && provider.data.followers && <div
                                    css={socialItem({
                                        mode,
                                        reducedHeader,
                                        swapAvatar,
                                        isEven
                                    })}><FontAwesomeIcon icon={faUsers}/>
                                    <span>{provider.data && provider.data.followers}</span>
                                </div>}
                            </div>
                        )
                    }
                    <div css={websiteTip({
                        mode,
                        reducedHeader,
                        swapAvatar,
                        isEven,
                        isInfo: ((provider.data && provider.data.posts) || (provider.data && provider.data.followers))
                    })}>
                        {
                            (provider.username || provider.description || provider.url) &&
                            <div css={mobileDisplay({
                                mode,
                                reducedHeader,
                                swapAvatar,
                                isEven
                            })}>
                                <div css={comfortPadding({
                                    mode,
                                    reducedHeader,
                                    swapAvatar,
                                    isEven
                                })}>
                                    {
                                        provider.username && (
                                            <Title darkMode={mode}
                                                   max={window.innerWidth < 421 ? 50 : 250}
                                                   white
                                                   content={`${provider.username !== 'unknown' ? `${provider.username}` : ``}`}/>
                                        )
                                    }
                                </div>
                                <Description darkMode={mode} white max={200}
                                             content={provider.description}/>
                                <div css={link({mode, reducedHeader, swapAvatar, isEven})}>
                                    <a href={provider.url} target='_blank'>
                                        {provider.url}
                                    </a>
                                </div>
                            </div>
                        }
                        <a href={provider.url} target="_blank"
                           onClick={() => createClickProviderHandler(linkId, provider.id)}
                           css={websiteTipContent({
                               mode,
                               reducedHeader,
                               swapAvatar,
                               isEven,
                               medias: (provider.medias && provider.medias.length) || 0
                           })}>
                            Visit <span>{provider.source === "unknown" ? parseProviderUrl(provider.url) : provider.source}</span>
                        </a>
                        {
                            (provider && provider.medias && provider.medias.length === 0) && (
                                <div css={marginEmpty({
                                    mode,
                                    reducedHeader,
                                    swapAvatar,
                                    isEven
                                })}/>
                            )
                        }
                    </div>
                    {provider && provider.html && (
                        <div dangerouslySetInnerHTML={{__html: provider.html}}/>
                    )}

                    {
                        provider && provider.medias && provider.medias.length > 0 && (
                            <>
                                <div css={imgContainer({
                                    mode,
                                    reducedHeader,
                                    swapAvatar,
                                    isEven
                                })}>
                                    <ResponsiveMasonry
                                        columnsCountBreakPoints={{
                                            420: 2,
                                            750: 3,
                                            900: 4,
                                            1280: 5
                                        }}
                                    >
                                        <Masonry
                                            options={masonryOptions} // default {}
                                            disableImagesLoaded={false} // default false
                                            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                                            gutter={'20px'}
                                        >
                                            {
                                                provider.medias.map((media, subIndex) => (
                                                    <React.Fragment
                                                        key={`media-${subIndex}`}>
                                                        <MediaViewer
                                                            external
                                                            darkMode={mode === "dark"}
                                                            embedImageStyle={imageRow({
                                                                mode,
                                                                reducedHeader,
                                                                swapAvatar,
                                                                isEven
                                                            })}
                                                            noBackground
                                                            media={media}
                                                            embedcss={row({
                                                                mode,
                                                                reducedHeader,
                                                                swapAvatar,
                                                                isEven
                                                            })}
                                                            onClick={createClickProviderHandler(linkId, provider.id)}
                                                            key={`video-${subIndex}`}
                                                            videoKey={`video-${parentIndex}`}
                                                            embedVideocss={video({
                                                                mode,
                                                                reducedHeader,
                                                                swapAvatar,
                                                                isEven
                                                            })}
                                                        />
                                                    </React.Fragment>
                                                ))
                                            }
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </div>
                                <CornerLink darkMode={mode} url={provider.url}
                                            onClickAction={createClickProviderHandler(linkId, provider.id)}/>
                            </>

                        )
                    }
                </div>
            </AppContainer>
        </Element>
    )
}

export default ProviderData;
