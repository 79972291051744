import React, { Component } from 'react';
import { jsx, css, keyframes, Global } from '@emotion/react'
import Theme from "../theme";
import {style} from "../styles/common";
import Button from "./button.component";
import {centeredOption, pointer} from "../pages/Showroom/showroom.style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSun} from "@fortawesome/free-regular-svg-icons/faSun";
import {faMoon} from "@fortawesome/free-regular-svg-icons/faMoon";

export default class BarMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visibleMenu: false
        }
    }

    render() {
        const { } = this.props;
        const { mode, switchTheme } = this.context;

        const headerMenu = css`
          display: flex;
          height: 50px;
          align-items: center;
          border-top: solid 1px rgba(255, 255, 255, .02);
          background: ${style(mode).color.headerBackground};
          width: calc(100% + 40px);
          margin-left: -20px;
          color: ${style(mode).color.switchableTextColor};
          border-bottom-left-radius: ${style(mode).mainRadius};
          border-bottom-right-radius: ${style(mode).mainRadius};
          box-shadow: ${style(mode).shadowBottom};
          position: sticky;
          z-index: 101;
          top: 58px;

          @media (max-width: ${style(mode).mobile}) {
            width: calc(100% + 30px);
          }
        `;
        const headerMenuContent = css`
          width: 100%;
          height: 100%;
          margin: auto;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 10px;

          @media (max-width: ${style(mode).mobile}) {
            padding-left: 10px;
          }
        `;

        const headerMenuItemsLeft = css`
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          max-width: 400px;
        `;

        const headerMenuItemsRight = css`
          display: flex;
          height: 100%;
          justify-content: flex-end;
          width: 100%;
        `;

        const themeStyle = css`
          display: flex;
          flex-wrap: nowrap;
          flex-shrink: 0;
          padding-right: 20px;

          @media (max-width: ${style(mode).mobile}) {
            display: none;
          }
        `;

        const headerMenuItem = css`
          padding-left: 16px;
          padding-right: 16px;
          font-weight: 300;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          height: 100%;
          color: ${style(mode).color.switchableTextColor};
          
          &:first-of-type {
            font-weight: bold;
            font-size: 16px;
          }
          a {
            color: ${style(mode).color.switchableTextColor};
            
            button {
              @media (max-width: ${style(mode).mobile}) {
                font-size: 12px;
                padding: 0;
                padding-left: 8px;
                padding-right: 8px;
              }
            }
          }
          
          &:hover {
            border-bottom: solid 2px  ${style(mode).color.violet};
          }
          @media (max-width: ${style(mode).mobile}) {
            font-size: 12px;
            &:first-of-type {
              font-weight: bold;
              font-size: 13px;
            }
          }
        `;

        const headerMenuItemNoHover = css`
          padding-left: 16px;
          padding-right: 16px;
          font-weight: 300;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          height: 100%;
          color: ${style(mode).color.switchableTextColor};
          
          &:first-of-type {
            font-weight: bold;
            font-size: 16px;
          }
          a {
            color: ${style(mode).color.switchableTextColor};
            
            button {
              @media (max-width: ${style(mode).mobile}) {
                font-size: 12px;
                padding: 0;
                padding-left: 8px;
                padding-right: 8px;
              }
            }
          }
          
          @media (max-width: ${style(mode).mobile}) {
            font-size: 12px;
            &:first-of-type {
              font-weight: bold;
              font-size: 13px;
            }
          }
        `;

        return (
            <div css={headerMenu}>
                <div css={headerMenuContent}>
                    <div css={headerMenuItemsLeft}>
                        <div css={headerMenuItem}>
                            <a href="/">Home</a>
                        </div>
                        <div css={headerMenuItem}>
                            <a href="/">Newest</a>
                        </div>
                        <div css={headerMenuItem}>
                            <a href="/create">TOP 10</a>
                        </div>
                    </div>
                    <div css={headerMenuItemsRight}>
                        <div css={headerMenuItemNoHover}>
                            <a href="/create">
                                <Button mini label="Create"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div css={themeStyle}>
                    <div css={centeredOption({mode})}>
                        <div css={pointer} onClick={switchTheme}>
                            <FontAwesomeIcon icon={mode ? faSun : faMoon} /> {mode === 'dark' ? 'Disable' : 'Enable'} dark theme
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

BarMenu.contextType = Theme;