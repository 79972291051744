import { css } from '@emotion/react';
import rubberSource from '../images/rubber.png';
import React from "react";
import {style} from "../styles/common";

const Rubber = ({
        content,
        staticRubber,
        darkMode
    }) => {

    const rubber = css`       
        background: ${style(darkMode).color.violet};
        height: 40px;
        width: 200px;
      color: #FFFFFF;
        transform: rotate(-24deg);
        margin-left: -45px;
        margin-top: -21px;
        box-shadow: rgb(0 0 0 / 18%) 0 6px 10px;

          @media (max-width: ${style(darkMode).mobile}) {
            height: 25px;
            margin-left: -32px;
            margin-top: -12px;
          }
    `;


        const rubberStatic = css`       
        height: 40px;
        width: 200px;
        margin-left: -45px;
        margin-top: -21px;
    `;

        const rubberContent = css`       
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 24px;
        text-shadow: none;
        font-size: 20px;
          text-transform: capitalize;
          box-shadow: rgb(0 0 0 / 18%) 0 6px 10px;

          @media (max-width: ${style(darkMode).mobile}) {
            font-size: 14px!important;
            font-weight: 500;
          }
    `;

        const rubberContentStatic = css`       
        position: absolute;
        transform: rotate(-24deg);
        max-width: 100px;
        height: 100%;
        max-height: 20px;
        display: flex;
        align-items: center;
        padding-left: 24px;
        text-shadow: none;
        font-size: 18px;
        color: #FFFFFF;
        font-weight: 500;
        margin-top: 52px;
        z-index: 2;
        margin-left: 24px;
          text-transform: capitalize;
          @media (max-width: ${style(darkMode).mobile}) {
            font-size: 14px!important;
            font-weight: 00;
          }
    `;

        const rubberImg = css`       
        width: 100px;
        margin-left: 42px;
        margin-top: 37px;
        position: absolute;
    `;

    return (
        staticRubber ? (
            <div css={rubberStatic}>
                <div css={rubberContentStatic} style={{
                    fontSize: content.length < 10 ? 16 : 14
                }}>{content}</div>
                <img css={rubberImg} src={rubberSource} />
            </div>
        ): (
        <div css={rubber}>
            <div css={rubberContent} style={{
                fontSize: content.length < 10 ? 20 : 14
            }}>{content}</div>
        </div>
        )
    )
}


export default Rubber;
